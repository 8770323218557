<template>
  <article
    class="
      about
      my-12
    "
  >
    <h1 class="display-1 mb-12">Conditions générales d'utilisation</h1>

    <h2 class="headline">
      1. Acceptation des conditions générales d'utilisation, version en vigueur en date du 21 octobre 2008
    </h2>

    <p>
      TrouverSonCadeau.com (ci-après « le site ») vous propose, gratuitement et sans inscription préalable, de
      rechercher et de trouver des cadeaux facilement selon une liste de critères subjectifs.
    </p>

    <p>
      Tous les cadeaux proviennent des sites web des marchands référencés par le site. Les résultats qui vous
      sont fournis ne sont pas exhaustifs de l'ensemble des offres disponibles sur Internet et proviennent des
      données recueillies par le site auprès des marchands référencés ou avec lesquels IFEELGOODS SAS a conclu
      un partenariat.
    </p>

    <p>
      L'ordre de présentation par défaut des cadeaux dépend des critères sélectionnés (cochés) par l'utilisateur.
      Si l'utilisateur souhaite ensuite acheter le bien ou le service sélectionné, il doit alors se rendre sur le
      site web du Marchand proposé sur le site ou sur le site web marchand de son choix. Les produits proposés sur
      TrouverSonCadeau.com proviennent des catalogues produits des sites web marchands référencés.
    </p>

    <p>
      IFEELGOODS SAS ne saurait être tenu pour responsable de différences d'informations entre les données fournies
      par les sites marchands partenaires publiées sur TrouverSonCadeau.com et lesdits sites marchand. A ce titre,
      l'utilisateur déclare être parfaitement informé que lorsqu'il se rend sur un site marchand partenaire à partir
      de TrouverSonCadeau.com pour acheter un bien ou un service, il se soumet alors aux Conditions Générales
      D'Utilisation et de vente de ce Site Marchand.
    </p>

    <p>
      TrouverSonCadeau.com est étranger aux relations entre l'utilisateur de TrouverSonCadeau.com et le site
      Internet marchand partenaire.
    </p>

    <p>
      Dès lors, le paiement et la livraison de votre commande seront assurés par le site marchand partenaire chez qui
      la vente a été réalisée. IFEELGOODS SAS ne pourra être tenue responsable en cas de litige de paiement ou de
      livraison : seule la responsabilité du site marchand pourra être engagée.
    </p>

    <h2 class="headline">2. Modification des présentes conditions (version en vigueur en date du 21/10/08)</h2>

    <p>
      TrouverSonCadeau.com se réserve le droit d'adapter ou de modifier à tout moment les présentes conditions
      générales d'utilisation. Toute utilisation de TrouverSonCadeau.com implique expressément l'adhésion sans
      réserve aux présentes conditions générales d'utilisation.
    </p>

    <h2 class="headline">3. Condition d'accès aux services du Site TrouverSonCadeau.com</h2>

    <p>
      L'accès au site est totalement gratuit et est libre. Cependant afin d'utiliser les Services, via votre
      ordinateur ou par l'intermédiaire d'autres supports, vous devez disposer d'un accès à Internet au débit
      approprié. Les éventuels frais correspondants à ces accès sont à votre seule charge.
    </p>

    <h2 class="headline">4. Modifications du site TrouverSonCadeau.com</h2>

    <p>
      TrouverSonCadeau.com se réserve le droit de modifier les pages du site. TrouverSonCadeau.com se réserve
      ainsi le droit de modifier ou supprimer toute idée de cadeau de sa base de données ou tout billet sur le
      blog ne respectant pas les conditions précisées plus haut.
    </p>

    <h2 class="headline">5. Protection des données</h2>

    <p>
      En conformité avec les dispositions de la Loi Informatique, fichiers et libertés du 6 janvier 1978, le
      site Web (https://trouversoncadeau.com) a fait l'objet d'une déclaration auprès de la Commission
      Nationale de l'informatique et des Libertés (CNIL) sous le n°1258810.
    </p>

    <h2 class="headline">6. Résiliation des inscriptions</h2>

    <p>
      Toute personne qui s'est enregistrée sur TrouverSonCadeau.com (rappels emails automatiques des dates
      anniversaires, sauvegardes des boîtes à idées, newsletters) peut résilier son inscription en envoyant
      un courrier électronique à contact@trouversoncadeau.com. Le Membre recevra un courriel de
      TrouverSonCadeau.com pour confirmer la résiliation de son compte. Les données relatives au Membre seront
      détruites à sa demande ou à l'expiration d'un délai de 12 mois suivant la résiliation du compte du Membre.
    </p>

    <h2 class="headline">7. Liens vers sites externes</h2>

    <p>
      TrouverSonCadeau.com contient des liens hypertextes vers d'autres sites Internet. Ces sites sont externes
      et totalement indépendants de TrouverSonCadeau.com, et ce dernier ne peut être tenu pour responsable quant
      à leur contenu, publicités, produits, services ou tout autre élément disponible sur ou à partir de ces sites
      ou sources externes. Si l'internaute accède à des sites, des pages ou des forums qui constitueraient une
      violation de la loi, il doit immédiatement fermer la page de consultation du site concerné au risque de
      voir sa responsabilité engagée.
    </p>
    <p>
      TrouverSonCadeau.com décline aussi toute responsabilité pour toutes les informations contenues sur des
      sites tiers où figurent des liens renvoyant sur le site de https://trouversoncadeau.com.
    </p>

    <h2 class="headline">8. Droits d'auteurs et marques</h2>

    <p>
      L'ensemble du contenu disponible sur le site TrouverSonCadeau.com (notamment le logo, le design, le texte,
      le graphisme, les interfaces et leur compilation) appartient à IFEELGOODS SAS ou à des tiers et est protégé
      par des droits de propriété intellectuelle.
    </p>

    <h2 class="headline">9. Droit applicable et clause attributive de compétence</h2>

    <p>
      Conformément à la directive 2000/31/CE du 8 juin 2000, ces conditions d'utilisation et l'accès au Site sont
      régis, interprétés et appliqués conformément au droit français.
    </p>
  </article>
</template>

<script>
export default {
  created() {
    this.$seo.update({
      title: 'Conditions Générales d\'Utilisation'
    })
    document.dispatchEvent(new Event('prerender-event'))
  }
}
</script>
