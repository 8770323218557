var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page)?_c('article',[_c('v-row',{attrs:{"tag":"header","align":"center"}},[_c('v-col',{staticClass:"\n        display-1\n        page-title\n        font-weight-light\n        primary--text\n      ",attrs:{"cols":_vm.page.banner && _vm.$vuetify.breakpoint.smAndUp ? 5 : 12,"tag":"h1"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.page.title)}}),_c('span',{staticClass:"ml-4 tsc-picto"})]),(_vm.page.banner)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 7 : 12}},[_c('v-img',{attrs:{"src":_vm.page.banner,"max-height":"300"}})],1):_vm._e()],1),(false && _vm.page.text_top)?_c('v-row',{staticClass:"body-1",attrs:{"tag":"h2"}},[_c('v-col',{domProps:{"innerHTML":_vm._s(_vm.page.text_top)}})],1):_vm._e(),_c('v-row',{class:{
      'mt-9': _vm.$vuetify.breakpoint.lgAndUp,
      'mt-3': _vm.$vuetify.breakpoint.xs
    },attrs:{"tag":"section"}},_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,class:{
        'py-9': _vm.$vuetify.breakpoint.smAndUp,
        'py-5': _vm.$vuetify.breakpoint.xs
      },attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 6 : 3,"tag":"article"}},[_c('Product',{attrs:{"product":product}})],1)}),1),_c('LoadMoreProducts',{staticClass:"mt-9"}),_c('SocialBlock',{staticClass:"mt-12",attrs:{"flipped":""}}),(_vm.page.text_bottom)?_c('v-row',{staticClass:"mt-12",attrs:{"tag":"footer"}},[_c('v-col',[_c('hr',{staticClass:"tsc-separator mb-12"}),(_vm.page.title_bottom)?_c('h2',{staticClass:"mb-6",class:{
          'headline-2': _vm.$vuetify.breakpoint.smAndUp,
          'headline font-weight-medium': _vm.$vuetify.breakpoint.xs
        },domProps:{"innerHTML":_vm._s(_vm.page.title_bottom)}}):_vm._e(),_c('div',{class:{
          'body-2': _vm.$vuetify.breakpoint.xs
        },domProps:{"innerHTML":_vm._s(_vm.page.text_bottom)}})])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }