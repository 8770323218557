import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Page from './views/Page.vue'
import Product from './views/Product.vue'
import Deals from './views/Deals.vue'
import Brands from './views/Brands.vue'
import Brand from './views/Brand.vue'
import BrandDeals from './views/BrandDeals.vue'
import About from './views/About.vue'
import NotFound from './views/NotFound.vue'
import CGU from './views/CGU.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/produit.html',
      name: 'product',
      component: Product
    },
    {
      path: '/404.html',
      name: 'not-found',
      component: NotFound
    },
    {
      path: '/a-propos.html',
      name: 'a-propos',
      component: About
    },
    {
      path: '/cgu.html',
      name: 'cgu',
      component: CGU
    },
    {
      path: '/bons-plans.html',
      name: 'deals',
      component: Deals
    },
    {
      path: '/bons-plans/:uri.html',
      name: 'brand-deals',
      component: BrandDeals
    },
    {
      path: '/nos-marques.html',
      name: 'brands',
      component: Brands
    },
    {
      path: '/nos-marques/:uri.html',
      name: 'brand',
      component: Brand
    },
    {
      path: '/:uri*.html',
      name: 'page',
      component: Page
    },
    {
      path: '/*',
      name: 'catch-all',
      component: {
        template: '',
        created() {
          window.location.href = '/404.html'
        }
      }
    }
  ]
})
