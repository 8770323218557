<template>
  <article
    class="deals"
  >
    <DealDialog></DealDialog>

    <v-row
      tag="header"
      align="center"
    >
      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 5 : 12"
      >
        <h1
          class="
            display-1
            deals-title
          "
        >
          <span>Codes promos</span>
          <br>
          <strong>& bons plans</strong>
          <i class="ml-4 tsc-picto"></i>
        </h1>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 7 : 12"
      >
        <v-img
          :src="$vuetify.breakpoint.smAndUp ? config.deals_banner : config.deals_banner_small"
          alt="Bons plans"
          max-height="300"
        ></v-img>
      </v-col>
    </v-row>

    <v-row
      tag="section"
      class="deals-list mt-12"
    >
      <v-col
        v-for="deal in deals"
        :key="deal.id"
        :cols="$vuetify.breakpoint.smAndUp ? 3 : 12"
        tag="article"
        class="deal-container"
      >
        <Deal
          :deal="deal"
          withImage
        ></Deal>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import Deal from '../components/Deal.vue'
import DealDialog from '../components/DealDialog.vue'

export default {
  components: {
    Deal,
    DealDialog
  },
  computed: {
    ...mapState(['deals', 'config'])
  },
  async created() {
    await this.$store.dispatch('loadAllDeals')
    this.$seo.update({
      title: 'Codes promos & bons plans',
      metaDescription: 'Retrouver les bons plans et codes promos de toutes les grandes marques sur TrouverSonCadeau.com'
    })
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setDeals', [])
    next()
  }
}
</script>
