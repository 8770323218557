import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API_URL
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  conf => conf,
  error => Promise.reject(error)
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const AxiosPlugin = {
  install(Vue) {
    Vue.axios = _axios
    Object.defineProperties(Vue.prototype, {
      $axios: { get() { return _axios } }
    })
  }
}

export default AxiosPlugin
