var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"home"},[(_vm.config.home_banner_page)?_c('HomeBanner'):_vm._e(),(_vm.config.home_products_1_page)?_c('ProductsHighlight',{class:{
      'mt-12': _vm.$vuetify.breakpoint.smAndUp,
      'mt-6': _vm.$vuetify.breakpoint.xs
    },attrs:{"title":_vm.config.home_products_1_text,"page":_vm.config.home_products_1_page}}):_vm._e(),_c('PagesHighlight',{staticClass:"mt-12",attrs:{"pageIds":[
      _vm.config.home_pages_1,
      _vm.config.home_pages_2,
      _vm.config.home_pages_3,
      _vm.config.home_pages_4
    ].filter(Boolean)}}),(_vm.config.home_products_2_page)?_c('ProductsHighlight',{staticClass:"mt-12",attrs:{"title":_vm.config.home_products_2_text,"page":_vm.config.home_products_2_page}}):_vm._e(),_c('Newsletter',{staticClass:"mt-12"}),_c('SocialBlock',{staticClass:"mt-12"}),_c('hr',{staticClass:"tsc-separator mt-12"}),_c('HomeFooter',{staticClass:"mt-12"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }