var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.target)?_c('v-row',{class:{
    'mx-n3': _vm.$vuetify.breakpoint.xs
  },attrs:{"align":"center","tag":"header","no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp && _vm.config.home_banner_ad_url ? 9 : 12}},[_c('router-link',{attrs:{"to":{ name: 'page', params: { uri: _vm.target.split('/') } }}},[_c('v-img',{attrs:{"src":_vm.$vuetify.breakpoint.smAndUp ? _vm.config.home_banner : _vm.config.home_banner_small,"alt":_vm._f("striptags")(_vm.config.home_banner_text),"height":_vm.$vuetify.breakpoint.smAndUp ? 300 : 450}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":_vm.$vuetify.breakpoint.smAndUp ? 'center' : 'start'}},[_c('v-col',{staticClass:"\n              d-flex\n              flex-column\n              align-start\n            ",class:{
              'mt-4': _vm.$vuetify.breakpoint.xs
            },attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 4 : 7,"offset":"1"}},[_c('h2',{staticClass:"white--text",class:{
                'display-1 font-weight-medium': _vm.$vuetify.breakpoint.mdAndUp,
                'headline-2': _vm.$vuetify.breakpoint.smAndDown
              }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.config.home_banner_text)}}),_c('span',{staticClass:"tsc-picto d-inline"})]),_c('v-btn',{staticClass:"mt-6",attrs:{"rounded":"","depressed":"","color":"accent"}},[_vm._v("Voir la sélection")])],1)],1)],1)],1)],1),(
      _vm.$vuetify.breakpoint.smAndUp
      && _vm.config.home_banner_ad_url
    )?_c('v-col',{attrs:{"cols":"3"}},[_c('a',{attrs:{"href":_vm.config.home_banner_ad_url,"rel":"noopener","target":"_blank","aria-label":"Home banner ad"}},[_c('v-img',{attrs:{"src":_vm.config.home_banner_ad_img,"alt":"home banner ad","height":"300px","position":"right center","contain":""}})],1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }