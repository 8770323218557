<template>
  <v-fade-transition>
    <v-container
      fluid
      class="
        pb-0
        px-0
        menu-mouseleave-target
        tsc-main-menu
      "
      v-if="selectedMenu[0]"
      @mouseleave="$emit('mouseleave', $event)"
    >
      <v-container class="pt-0">
        <v-row>
          <v-col
            :cols="$vuetify.breakpoint.mdAndDown ? 3 : 2"
            v-if="selectedMenu[0].nbLevels === 3"
          >
            <MenuLevel1
              :items="selectedMenu[0].children"
              :selectedMenu="selectedMenu"
            ></MenuLevel1>
          </v-col>

          <v-divider
            vertical
            class="my-3 menu-divider"
            v-if="selectedMenu[0].nbLevels === 3"
          ></v-divider>

          <v-col
            :cols="
              selectedMenu[0].nbLevels === 3
              ? (
                $vuetify.breakpoint.mdAndDown
                ? (config.menu_ad_url ? 7 : 9)
                : (config.menu_ad_url ? 8 : 10)
              )
              : (config.menu_ad_url ? 10 : 12)
            "
            class="py-0"
            :class="{
              'pl-6': selectedMenu[0].nbLevels === 3
            }"
          >
            <MenuLevel2
              :item="selectedMenu[0].nbLevels === 3 ? selectedMenu[1] : selectedMenu[0]"
            ></MenuLevel2>
          </v-col>

          <v-col
            cols="2"
            v-if="config.menu_ad_url"
          >
            <a
              :href="config.menu_ad_url"
              rel="noopener"
              target="_blank"
              aria-label="Menu ad"
            >
              <v-img
                :src="config.menu_ad_img"
                alt="menu ad"
                height="250px"
                position="right center"
                contain
              ></v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'

import MenuLevel1 from './menu/MenuLevel1.vue'
import MenuLevel2 from './menu/MenuLevel2.vue'

export default {
  name: 'Menu',
  components: {
    MenuLevel1,
    MenuLevel2
  },
  props: {
    selectedMenu: Object
  },
  computed: {
    ...mapState([
      'config'
    ])
  },
  watch: {
    $route() {
      this.$emit('mouseleave')
    }
  }
}
</script>

<style lang="sass">
.tsc-main-menu
  position: absolute
  left: 0
  top: 130px
  padding-top: 30px
  background: white
  z-index: 2

</style>
