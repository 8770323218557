<template>
  <v-fade-transition>
    <v-container
      fluid
      class="
        cookies-panel
      "
      v-if="show"
    >
      <v-row
        align="center"
      >
        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
          :offset="$vuetify.breakpoint.mdAndUp ? 2 : 0"
          class="body-2"
        >
          Nous utilisons des cookies destinés à améliorer votre expérience. En naviguant sur notre site,
          vous acceptez l’utilisation de cookies dans les conditions prévues par notre
          <router-link :to="{ name: 'cgu' }">politique de confidentialité</router-link>.
          <span class="more" @click="showDialog">En savoir plus</span>.
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12"
          class="
            d-flex
            justify-end
          "
        >
          <v-btn
            depressed
            rounded
            outlined
            color="accent"
            @click="accept"
          >
            <span class="mx-8">Ok</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
const STORAGE_KEY = 'cookies-accepted'
let initialScroll = 0

export default {
  name: 'CookiesPanel',
  data() {
    return {
      show: localStorage.getItem(STORAGE_KEY) !== '1'
    }
  },
  created() {
    setTimeout(() => {
      initialScroll = window.scrollY
      document.addEventListener('scroll', this.onScroll)
    }, 1000)
  },
  methods: {
    accept() {
      localStorage.setItem(STORAGE_KEY, '1')
      this.show = false
    },
    showDialog() {
      this.$store.commit('setCookiesDialog', { dialog: true })
    },
    onScroll() {
      if (Math.abs(initialScroll - window.scrollY) < 200) return
      this.show = false
      document.removeEventListener('scroll', this.onScroll)
    }
  }
}
</script>

<style lang="sass">
.cookies-panel
  position: fixed
  bottom: 0
  z-index: 3
  background-color: white
  border-top: 8px solid var(--v-accent-base)

  a,
  .more
    text-decoration: underline
    cursor: pointer
</style>
