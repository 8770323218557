<template>
  <article
    class="home"
  >
    <HomeBanner
      v-if="config.home_banner_page"
    ></HomeBanner>

    <ProductsHighlight
      v-if="config.home_products_1_page"
      :class="{
        'mt-12': $vuetify.breakpoint.smAndUp,
        'mt-6': $vuetify.breakpoint.xs
      }"
      :title="config.home_products_1_text"
      :page="config.home_products_1_page"
    ></ProductsHighlight>

    <PagesHighlight
      class="mt-12"
      :pageIds="[
        config.home_pages_1,
        config.home_pages_2,
        config.home_pages_3,
        config.home_pages_4
      ].filter(Boolean)"
    ></PagesHighlight>

    <ProductsHighlight
      v-if="config.home_products_2_page"
      class="mt-12"
      :title="config.home_products_2_text"
      :page="config.home_products_2_page"
    ></ProductsHighlight>

    <Newsletter
      class="mt-12"
    ></Newsletter>

    <SocialBlock
      class="mt-12"
    ></SocialBlock>

    <hr class="tsc-separator mt-12">

    <HomeFooter
      class="mt-12"
    ></HomeFooter>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import HomeBanner from '../components/HomeBanner.vue'
import HomeFooter from '../components/HomeFooter.vue'
import SocialBlock from '../components/SocialBlock.vue'
import ProductsHighlight from '../components/ProductsHighlight.vue'
import Newsletter from '../components/Newsletter.vue'
import PagesHighlight from '../components/PagesHighlight.vue'

export default {
  components: {
    HomeBanner,
    HomeFooter,
    SocialBlock,
    ProductsHighlight,
    PagesHighlight,
    Newsletter
  },
  computed: {
    ...mapState(['config'])
  },
  created() {
    this.$seo.update({
      metaDescription: this.config.home_meta_description
    })
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetProducts')
    next()
  }
}
</script>
