<template>
  <div
    class="
      deal-picto
      d-flex
      align-center
      justify-center

    "
    :style="{
      height: small ? '30px' : '100px'
    }"
  >
    <fa-icon
      icon="shipping-fast"
      :size="small ? '2x' : '4x'"
      v-if="deal.type === 'delivery'"
    ></fa-icon>

    <fa-icon
      icon="gift"
      :size="small ? '2x' : '4x'"
      v-if="deal.type === 'deal'"
    ></fa-icon>

    <span
      v-if="deal.type === 'discount'"
      :class="{
        'display-3': !small,
        'display-1': small
      }"
    >-{{ deal.discount }}</span>
  </div>
</template>

<script>
export default {
  name: 'DealPicto',
  props: {
    deal: {
      type: Object,
      required: true
    },
    small: Boolean
  }
}
</script>
