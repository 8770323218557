<template>
  <article
    class="brands"
  >
    <v-row tag="header">
      <v-col>
        <h1
          class="
            display-1
            brands-title
          "
        >Nos marques</h1>
      </v-col>
    </v-row>

    <v-row
      tag="section"
      class="brands-list"
    >
      <v-col
        v-for="brand in _brands"
        :key="brand.id"
        cols="4"
        tag="article"
        class="brand-container"
      >
        <v-card
          elevation="0"
          outlined
          class="
            pa-6
            brand
          "
          height="100%"
          :ripple="false"
          :to="{ name: 'brand', params: { uri: brand.uri } }"
        >
          <v-img
            lazy-src="/placeholder.svg"
            :transition="false"
            :src="brand.image || '/placeholder.svg'"
            contain
            :alt="brand.name"
            height="100px"
            class="mx-8 my-12"
          ></v-img>

          <v-card-text
            class="px-0 pb-0 text-center"
          >{{ brand.name }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['brands']),
    _brands() {
      return this.brands.filter(brand => brand.status === 'displayed')
    }
  },
  async created() {
    await this.$store.dispatch('getBrands')
    this.$seo.update({ title: 'Nos marques' })
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  }
}
</script>
