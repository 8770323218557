<template>
  <div
    class="
      deal
      pa-6
      d-flex
      flex-column
      text-center
    "
  >
    <router-link
      v-if="withImage"
      :to="{
        name: 'brand-deals',
        params: { uri: deal.brandURI }
      }"
    >
      <v-img
        v-if="deal.brandImage"
        :src="deal.brandImage"
        height="100px"
        max-height="100px"
        contain
        :alt="`Logo ${deal.partnerName}`"
      ></v-img>

      <div
        class="title"
        v-if="!deal.brandImage"
        style="line-height: 100px"
      >{{ deal.partnerName }}</div>
    </router-link>

    <DealPicto
      :deal="deal"
      v-if="!withImage"
    ></DealPicto>

    <h3
      class="
        title
        primary--text
        font-weight-bold
        my-4
      "
      role="link"
      @click="openDeal(deal)"
    >
      <span>{{ deal.shortDescription }}</span>
    </h3>

    <div
      class="
        body-2
        grey--text
        text--darken-1
        mb-4
      "
      v-line-clamp:2
      v-html="deal.description"
    ></div>

    <v-card-actions
      class="
        mt-auto
        flex-column
        px-0
      "
    >
      <v-btn
        color="primary"
        depressed
        rounded
        small
        @click="openDeal(deal)"
      >Découvrir le code</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import DealPicto from './DealPicto.vue'

const LS_KEY = 'tsc_d'

export default {
  name: 'Deal',
  components: {
    DealPicto
  },
  props: {
    deal: {
      type: Object,
      required: true
    },
    withImage: Boolean
  },
  created() {
    const encodedId = localStorage.getItem(LS_KEY)
    if (!encodedId) return

    const [id] = atob(encodedId).split('#')
    if (`${this.deal.id}` === `${id}`) {
      this.$store.commit('setDealDialog', { deal: this.deal, dialog: true })
      localStorage.removeItem(LS_KEY)
    }
  },
  methods: {
    openDeal(deal) {
      localStorage.setItem(LS_KEY, btoa(`${deal.id}#${Date.now()}`))
      window.open(window.location.href, '_blank')
      window.location = deal.url
    }
  }
}
</script>

<style lang="sass">
.deal
  border-radius: 10px
  border: 1px solid #dedede
  height: 100%
</style>
