<template>
  <div>
    <v-row
      v-if="
        item
        && item.children.every(c => !c.children.length)
      "
    >
      <v-col
        cols="9"
        class="px-6"
      >
        <router-link
          class="
            body-2
            d-block
            font-weight-medium
            py-1
            menu-link
          "
          v-for="child in item.children.filter(c => c.page_uri)"
          :key="child.id"
          :to="{
            name: 'page',
            params: { uri: child.page_uri.split('/') }
          }"
        >{{ child.name }}</router-link>

        <div
          class="
            font-weight-medium
            body-2
          "
          v-for="child in item.children.filter(c => !c.page_uri)"
          :key="child.id"
        >{{ child.name }}</div>
      </v-col>

      <v-col
        cols="3"
        v-if="config.menu_optional_ad_url"
      >
        <a
          :href="config.menu_optional_ad_url"
          rel="noopener"
          target="_blank"
          aria-label="Menu optional ad"
        >
          <v-img
            :src="config.menu_optional_ad_img"
            alt="menu optional ad"
            height="250px"
            position="right center"
            contain
          ></v-img>
        </a>
      </v-col>
    </v-row>

    <v-row
      v-if="
        item
        && item.children.some(c => c.children.length)
      "
    >
      <v-col
        :cols="
          $vuetify.breakpoint.mdAndDown
          ? (optionalAdDisplayed ? 4 : 3)
          : (optionalAdDisplayed ? 3 : 2)
        "
        v-for="child in item.children"
        :key="child.id"
      >
        <router-link
          role="link"
          class="
            accent--text
            mb-2
            body-1
            font-weight-medium
          "
          v-if="child.page_uri"
          :to="{
            name: 'page',
            params: { uri: child.page_uri.split('/') }
          }"
        >{{ child.name }}</router-link>

        <h4
          class="
            font-weight-medium
            accent--text
            mb-2
            body-1
          "
          v-if="!child.page_uri"
        >{{ child.name }}</h4>

        <div
          v-for="grandchild in child.children"
          :key="grandchild.id"
          class="
            body-2
            font-weight-medium
            py-1
          "
        >
          <router-link
            v-if="grandchild.page_uri"
            :to="{
              name: 'page',
              params: { uri: grandchild.page_uri.split('/') }
            }"
            class="menu-link"
          >{{ grandchild.name }}</router-link>

          <span
            v-if="!grandchild.page_uri"
          >{{ grandchild.name }}</span>
        </div>
      </v-col>

      <v-spacer
        v-if="optionalAdDisplayed"
      ></v-spacer>

      <v-col
        cols="3"
        v-if="optionalAdDisplayed"
      >
        <a
          :href="config.menu_optional_ad_url"
          rel="noopener"
          target="_blank"
          aria-label="Menu optional ad"
        >
          <v-img
            :src="config.menu_optional_ad_img"
            alt="menu optional ad"
            height="250px"
            position="right center"
            contain
          ></v-img>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuLevel2',
  props: {
    item: Object
  },
  computed: {
    ...mapState([
      'config'
    ]),
    optionalAdDisplayed() {
      return this.config.menu_optional_ad_url
        && (
          (this.$vuetify.breakpoint.lgAndUp && this.item.children.length < 4)
          || (this.$vuetify.breakpoint.mdAnnDown && this.item.children.length < 3)
        )
    }
  }
}
</script>

<style lang="sass">
.menu-link
  &:hover
    color: var(--v-accent-base)
</style>
