function update({
  title,
  metaDescription
} = {}) {
  document.title = title
    ? `${title} | TrouverSonCadeau.com`
    : 'Idée Cadeau | Cadeaux Originaux sur TrouverSonCadeau.com'

  if (metaDescription) {
    let meta = document.querySelector('meta[name="description"]')
    if (!meta) {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'description')
      document.head.append(meta)
    }
    meta.setAttribute('content', metaDescription)
  }
}

const SeoPlugin = {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $seo: { get() { return { update } } }
    })
  }
}

export default SeoPlugin
