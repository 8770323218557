<template>
  <article
    class="
      about
      my-12
      text-center
    "
  >
    <h1 class="display-1 mb-12">Cette page n'existe pas</h1>

    <p>
        Vous avez trouvé la page 404, mais toujours pas le cadeau idéal...
    </p>

    <p>
      Pas de panique ! Vous trouverez sûrement votre bonheur dans notre sélection de cadeaux sur notre page d'accueil !
    </p>

    <p>
      <v-btn
        color="accent"
        :to="{ name: 'home' }"
      >Revenir à l'accueil</v-btn>
    </p>
  </article>
</template>

<script>
export default {
  created() {
    this.$seo.update({
      title: 'Page introuvable'
    })
    document.dispatchEvent(new Event('prerender-event'))
  }
}
</script>
