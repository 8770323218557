var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.item
      && _vm.item.children.every(function (c) { return !c.children.length; })
    )?_c('v-row',[_c('v-col',{staticClass:"px-6",attrs:{"cols":"9"}},[_vm._l((_vm.item.children.filter(function (c) { return c.page_uri; })),function(child){return _c('router-link',{key:child.id,staticClass:"\n          body-2\n          d-block\n          font-weight-medium\n          py-1\n          menu-link\n        ",attrs:{"to":{
          name: 'page',
          params: { uri: child.page_uri.split('/') }
        }}},[_vm._v(_vm._s(child.name))])}),_vm._l((_vm.item.children.filter(function (c) { return !c.page_uri; })),function(child){return _c('div',{key:child.id,staticClass:"\n          font-weight-medium\n          body-2\n        "},[_vm._v(_vm._s(child.name))])})],2),(_vm.config.menu_optional_ad_url)?_c('v-col',{attrs:{"cols":"3"}},[_c('a',{attrs:{"href":_vm.config.menu_optional_ad_url,"rel":"noopener","target":"_blank","aria-label":"Menu optional ad"}},[_c('v-img',{attrs:{"src":_vm.config.menu_optional_ad_img,"alt":"menu optional ad","height":"250px","position":"right center","contain":""}})],1)]):_vm._e()],1):_vm._e(),(
      _vm.item
      && _vm.item.children.some(function (c) { return c.children.length; })
    )?_c('v-row',[_vm._l((_vm.item.children),function(child){return _c('v-col',{key:child.id,attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown
        ? (_vm.optionalAdDisplayed ? 4 : 3)
        : (_vm.optionalAdDisplayed ? 3 : 2)}},[(child.page_uri)?_c('router-link',{staticClass:"\n          accent--text\n          mb-2\n          body-1\n          font-weight-medium\n        ",attrs:{"role":"link","to":{
          name: 'page',
          params: { uri: child.page_uri.split('/') }
        }}},[_vm._v(_vm._s(child.name))]):_vm._e(),(!child.page_uri)?_c('h4',{staticClass:"\n          font-weight-medium\n          accent--text\n          mb-2\n          body-1\n        "},[_vm._v(_vm._s(child.name))]):_vm._e(),_vm._l((child.children),function(grandchild){return _c('div',{key:grandchild.id,staticClass:"\n          body-2\n          font-weight-medium\n          py-1\n        "},[(grandchild.page_uri)?_c('router-link',{staticClass:"menu-link",attrs:{"to":{
            name: 'page',
            params: { uri: grandchild.page_uri.split('/') }
          }}},[_vm._v(_vm._s(grandchild.name))]):_vm._e(),(!grandchild.page_uri)?_c('span',[_vm._v(_vm._s(grandchild.name))]):_vm._e()],1)})],2)}),(_vm.optionalAdDisplayed)?_c('v-spacer'):_vm._e(),(_vm.optionalAdDisplayed)?_c('v-col',{attrs:{"cols":"3"}},[_c('a',{attrs:{"href":_vm.config.menu_optional_ad_url,"rel":"noopener","target":"_blank","aria-label":"Menu optional ad"}},[_c('v-img',{attrs:{"src":_vm.config.menu_optional_ad_img,"alt":"menu optional ad","height":"250px","position":"right center","contain":""}})],1)]):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }