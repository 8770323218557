<template>
  <v-dialog
    v-model="cookiesDialog"
    max-width="600"
    overlay-color="white"
    content-class="cookies-dialog"
    scrollable
  >
    <v-card
      flat
      tile
    >
      <v-card-text
        style="position: relative"
        v-perfect-scrollbar
        class="pa-6"
      >
        <span
          class="dialog-close"
          role="button"
          @click="cookiesDialog = false"
        ></span>

        <div
          class="
            headline
            primary--text
            font-weight-bold
            text-center
          "
        >Les Cookies</div>

        <div
          class="
            mt-10
            body-2
            grey--text
            text--darken-1
          "
        >
          Lorsque vous consultez un site Web, des données peuvent être stockées dans votre navigateur ou
          récupérées à partir de celui-ci, généralement sous la forme de cookies. Ces informations peuvent
          porter sur vous, sur vos préférences ou sur votre appareil et sont principalement utilisées pour
          s’assurer que le site Web fonctionne correctement. Les informations ne permettent généralement
          pas de vous identifier directement, mais peuvent vous permettre de bénéficier d’une expérience
          Web personnalisée.
        </div>

        <h4
          class="
            mt-6
            body-2
            grey--text
            text--darken-1
            font-weight-bold
          "
        >Cookies Google Analytics</h4>

        <div
          class="
            mt-2
            body-2
            grey--text
            text--darken-1
          "
        >
          Ces cookies permettent d’obtenir des statistiques de fréquentation anonymes du site TrouverSonCadeau.com
          afin d’optimiser son ergonomie, sa navigation et ses contenus. En désactivant ces cookies, nous ne
          pourrons pas analyser le trafic du site TrouverSonCadeau.com.
        </div>

        <div
          class="
            mt-10
            d-flex
            justify-center
          "
        >
          <v-btn
            rounded
            depressed
            small
            color="primary"
            @click="cookiesDialog = false"
          >
            <span class="mx-8">Ok</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CookiesDialog',
  computed: {
    cookiesDialog: {
      get() { return this.$store.state.cookiesDialog },
      set(dialog) { this.$store.commit('setCookiesDialog', { dialog }) }
    }
  }
}
</script>

<style lang="sass">
.cookies-dialog
  border-radius: 40px !important

  .ps__rail-x
    display: none !important
</style>
