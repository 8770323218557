var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageIds.length === 4)?_c('v-row',{staticClass:"pages-highlight",attrs:{"tag":"section"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : (_vm.$vuetify.breakpoint.smAndUp ? 6 : 12),"order":_vm.$vuetify.breakpoint.mdAndUp ? 0 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 6 : 12}},[_c('PageHighlightBlock',{attrs:{"page":_vm._pages[0]}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"cols":"6"}},[_c('PageHighlightBlock',{attrs:{"page":_vm._pages[1]}})],1):_vm._e()],1)],1),_c('v-col',{staticClass:"\n      py-0\n      d-flex\n      align-center\n    ",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 9,"order":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 1}},[_c('v-container',{staticClass:"pa-0",class:{
        'pages-highlight__title-container': _vm.$vuetify.breakpoint.mdAndUp
      },attrs:{"fluid":""}},[_c('v-row',{staticClass:"\n          pages-highlight__title\n          d-inline-block\n        ",class:{
          'pr-8 pb-5 mb-2 mt-4': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"primary--text",class:{
              'py-12 px-3': _vm.$vuetify.breakpoint.mdAndUp,
              'headline-2': _vm.$vuetify.breakpoint.lgAndUp || _vm.$vuetify.breakpoint.smAndDown,
              'headline font-weight-medium': _vm.$vuetify.breakpoint.md
            }},[_vm._v("Nos meilleures catégories")])])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : (_vm.$vuetify.breakpoint.smAndUp ? 6 : 12),"order":"3"}},[_c('v-row',{staticClass:"flex-nowrap",style:({
        overflow: _vm.$vuetify.breakpoint.xs ? 'scroll' : null
      })},[(_vm.$vuetify.breakpoint.xs)?_c('v-col',{attrs:{"cols":"9"}},[_c('PageHighlightBlock',{attrs:{"page":_vm._pages[1]}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 6 : 9}},[_c('PageHighlightBlock',{attrs:{"page":_vm._pages[2]}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 6 : 9}},[_c('PageHighlightBlock',{attrs:{"page":_vm._pages[3]}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }