function actionButton(text, classname, block) {
  if (!block) classname += ' ml-2'
  return `
    ${block ? '<br>' : ''}
    <span
      class="caption ${classname}"
      role="button"
      style="text-decoration: underline; white-space: nowrap"
    >${text}</span>
  `
}

export default {
  inserted(el, bindings) {
    const nbLines = parseInt(bindings.arg, 10)

    const { lineHeight } = window.getComputedStyle(el)
    const targetHeight = nbLines * parseInt(lineHeight.replace(/\D/g, ''), 10)

    el.style.height = `${targetHeight}px`
    el.style.overflow = 'hidden'

    if (el.scrollHeight <= targetHeight) return

    const blockAction = bindings.modifiers['block-action']
    const showLessAction = actionButton('Voir moins', 'show-less', blockAction)
    const showMoreAction = actionButton('Voir plus', 'show-more', blockAction)

    const originalContent = el.textContent
    el.innerHTML = `
      <span>${originalContent}</span>
      ${showLessAction}
    `
    el.style.height = 'auto'
    const originalHeight = window.getComputedStyle(el).height
    el.style.height = `${targetHeight}px`

    let cutContent = originalContent
    while (el.scrollHeight > targetHeight) {
      cutContent = cutContent.substr(0, cutContent.length - 1)
      el.innerHTML = `
        <span>${cutContent}...</span>
        ${showMoreAction}
      `
    }

    el.style.transition = '.3s'

    el.addEventListener('click', (event) => {
      if (event.target.classList.contains('show-more')) {
        el.innerHTML = `
          <span>${originalContent}</span>
          ${showLessAction}
        `
        el.style.height = originalHeight
      }
      if (event.target.classList.contains('show-less')) {
        el.style.height = `${targetHeight}px`
        setTimeout(() => {
          el.innerHTML = `
            <span>${cutContent}...</span>
            ${showMoreAction}
          `
        }, 300)
      }
    })
  }
}
