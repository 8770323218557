<template>
  <v-row
    tag="section"
    no-gutters
    align="center"
    class="
      py-8
      newsletter-section
    "
    :class="{
      'mx-n3': $vuetify.breakpoint.xs
    }"
  >
    <v-col
      :cols="$vuetify.breakpoint.xs ? 12 : 6"
      class="white--text"
      :class="{
        'pa-12 text-right': $vuetify.breakpoint.smAndUp,
        'pa-6 text-center': $vuetify.breakpoint.xs
      }"
    >
      <h2
        class="
          headline
          font-weight-medium
        "
      >
        Les prises de tête pour<br>trouver le cadeau idéal ?
        <span
          class="tsc-picto d-inline"
          v-if="$vuetify.breakpoint.smAndUp"
        ></span>
      </h2>

      <h3
        class="
          title
          font-weight-light
        "
      >En un email on vous dit tout :)</h3>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.xs ? 12 : ($vuetify.breakpoint.lgAndUp ? 5 : 6)"
      :class="{
        'py-12 pl-6 pr-12': $vuetify.breakpoint.smAndUp,
        'pa-6': $vuetify.breakpoint.xs
      }"
    >
      <v-form
        class="
          d-flex
          newsletter-form
        "
        :class="{
          'pl-6': $vuetify.breakpoint.lgAndUp,
          'pl-2': $vuetify.breakpoint.md || $vuetify.breakpoint.sm,
          'flex-column': $vuetify.breakpoint.xs
        }"
        @submit.prevent="submit()"
      >
        <v-text-field
          type="email"
          name="email"
          v-model="email"
          :placeholder="$vuetify.breakpoint.sm ? 'Adresse e-mail' : 'Votre adresse e-mail'"
          solo
          flat
          hide-details
          :class="{
            'mx-8 my-2': $vuetify.breakpoint.xs
          }"
        >
          <template
            slot="append"
            v-if="$vuetify.breakpoint.xs && !email"
          >
            <span class="tsc-picto"></span>
          </template>
        </v-text-field>

        <v-btn
          depressed
          type="submit"
          :color="subscribed ? 'green accent-4' : 'accent'"
          class="newsletter-button"
          :small="$vuetify.breakpoint.sm"
          :width="$vuetify.breakpoint.sm ? '140px' : ($vuetify.breakpoint.xs ? null :'170px')"
        >
          <span
            v-if="subscribed"
            class="d-flex align-center"
          >
            <svg
              width="23"
              height="23"
              class="mr-2"
            >
              <path
                transform="scale(.75)"
                fill="#FFF"
                d="
                  M15 26.687l-1.813-1.644c-6.437-5.844-10.688-9.7-10.688-14.419 0-3.856 3.019-6.875 6.875-6.875 2.175
                  0 4.262 1.012 5.625 2.606 1.363-1.594 3.45-2.606 5.625-2.606 3.856 0 6.875 3.019 6.875 6.875 0
                  4.719-4.25 8.575-10.688 14.419l-1.813 1.644z
                "
              ></path>
            </svg>
            <span class="white--text">À bientôt</span>
          </span>
          <span v-if="!subscribed">Rejoignez-nous</span>
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Newsletter',
  data() {
    return {
      email: null,
      subscribed: false
    }
  },
  methods: {
    async submit() {
      if (!this.email) return
      if (!/[^@]+@.+/.test(this.email)) return
      const token = await this.$recaptcha.execute('newsletter')
      await this.$axios.post('/public/newsletter', { email: this.email, token })
      this.subscribed = true
      setTimeout(() => { this.subscribed = false }, 3000)
      this.email = null
    }
  }
}
</script>

<style lang="sass">
.newsletter-section
  background: url('/pattern.svg') 1px 36px / 59px #2C3E50

.newsletter-form
  border-radius: 24px
  background-color: white
  overflow: hidden

.newsletter-button
  height: 48px !important
  vertical-align: top
  border-radius: 0

.v-input.text-center input
  text-align: center
</style>
