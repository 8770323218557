<template>
  <v-row
    tag="section"
    class="products-highlight"
    v-if="target"
  >
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 2 : 9"
      class="py-0"
    >
      <div
        class="
          products-highlight__title
          d-inline-block
        "
        :class="{
          'mt-12 mr-7': $vuetify.breakpoint.mdAndUp,
          'pr-8 pb-5 mb-2 mt-4': $vuetify.breakpoint.smAndDown
        }"
      >
        <h3
          class="primary--text"
          :class="{
            'headline-2': $vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smAndDown,
            'headline font-weight-medium': $vuetify.breakpoint.md
          }"
          v-html="title"
        ></h3>

        <router-link
          v-if="$vuetify.breakpoint.mdAndUp"
          class="
            d-block
            mt-9
            pr-4
            pb-3
            text-end
          "
          :to="{ name: 'page', params: { uri: target.split('/') } }"
        >C'est par ici</router-link>
      </div>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12"
      class="py-0"
    >
      <v-row>
        <v-col
          v-for="product in products"
          :key="product.id"
          :cols="$vuetify.breakpoint.smAndUp ? 3 : 6"
          tag="article"
        >
          <Product :product="product"></Product>
        </v-col>
      </v-row>

      <v-row
        v-if="$vuetify.breakpoint.smAndDown"
        justify="center"
      >
        <v-btn
          rounded
          :small="$vuetify.breakpoint.xs"
          depressed
          color="accent"
          class="mt-6"
          :to="{ name: 'page', params: { uri: target.split('/') } }"
        >C'est par ici !</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import Product from './Product.vue'

export default {
  name: 'ProductsHighlight',
  components: {
    Product
  },
  props: {
    title: {
      type: String,
      default: 'Nos<br>Meilleurs<br>Produits'
    },
    page: String
  },
  data() {
    return {
      products: []
    }
  },
  computed: {
    ...mapState(['pages']),
    target() {
      const page = this.pages.find(p => p.id.toString() === this.page)
      if (!page) return null
      return page.uri
    }
  },
  async created() {
    await this.$store.dispatch('getPages')
    const url = `page/${this.page}/products`
    const params = {
      itemsPerPage: 4,
      page: 1
    }
    const response = await this.$axios.get(`/public/${url}`, { params })
    this.products = response.data.data
  }
}
</script>

<style lang="sass">
.products-highlight
  &__title
    position: relative

    &:before
      content: ''
      height: 8px
      width: 45px
      border-radius: 4px
      background: var(--v-primary-base)
      position: absolute
      right: 0
      bottom: 0

    &:after
      content: ''
      height: 45px
      width: 8px
      border-radius: 4px
      background: var(--v-accent-base)
      position: absolute
      right: 0
      bottom: 0
</style>
