<template>
  <article v-if="product">
    <v-row
      tag="section"
    >
      <v-col class="text-center">
        <v-progress-circular
          :size="100"
          :width="10"
          color="accent"
          indeterminate
        ></v-progress-circular>

        <div class="mt-6">Redirection en cours...</div>

        <div class="mt-12">Si rien ne se passe au bout de 10 secondes, cliquez sur le lien suivant :</div>

        <div class="mt-6">
          <a
            class="
              product-link
              accent--text
            "
            :href="product.url"
          >{{ product.url }}</a>
        </div>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'product'
    ])
  },
  async created() {
    await this.loadProduct(this.$route.query.id)
  },
  methods: {
    async loadProduct(id) {
      await this.$store.dispatch('getProduct', id)
      if (this.product.url) {
        window.location.href = this.$filters.decodeUri(this.product.url)
      } else {
        this.$router.push({ name: 'not-found' })
      }
    }
  }
}
</script>

<style lang="sass">
.product-link
  text-decoration: underline
</style>
