function monetize(value, currency) {
  if (value == null) return value
  return new Intl.NumberFormat(navigator.language, { style: 'currency', currency })
    .format(value)
    // transform 100,00 € into 100 € but not 100,99 €
    .replace(/[,.]00(\s?\D+)$/, (...args) => args[1])
}

function striptags(value) {
  if (!value) return value
  return value.replace(/<br>/g, ' ').replace(/<[^>]*>/g, '')
}

function decodeUri(value) {
  if (!value) return value
  return window.decodeURI(value)
}

const filters = { monetize, striptags, decodeUri }

const FiltersPlugin = {
  install(Vue) {
    Vue.$filters = filters
    Vue.filter('monetize', monetize)
    Vue.filter('striptags', striptags)
    Vue.filter('decodeUri', decodeUri)
    Object.defineProperties(Vue.prototype, {
      $filters: { get() { return filters } }
    })
  }
}

export default FiltersPlugin
