var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"\n    tsc-app__footer\n    white--text\n    font-weight-bold\n    pa-0\n    mt-12\n  ",attrs:{"color":"primary"}},[_c('v-container',{class:{
      'py-12': _vm.$vuetify.breakpoint.smAndUp,
      'py-6': _vm.$vuetify.breakpoint.xs
    }},[_c('v-row',[_c('v-col',{staticClass:"d-flex py-0",class:{
          'flex-column px-6': _vm.$vuetify.breakpoint.xs,
          'align-center': _vm.$vuetify.breakpoint.smAndUp
        }},[(_vm.$vuetify.breakpoint.xs)?_c('v-img',{staticStyle:{"position":"absolute","right":"24px","top":"24px"},attrs:{"src":"/logo-icon-white.svg","alt":"Trouver son cadeau logo small","width":"40px"}}):_vm._e(),_c('router-link',{staticClass:"white--text mr-8",attrs:{"to":{ name: 'a-propos' }}},[_vm._v("À propos")]),_c('a',{staticClass:"white--text",class:{
            'mr-8': _vm.$vuetify.breakpoint.smAndUp,
            'mt-4': _vm.$vuetify.breakpoint.xs
          },attrs:{"href":"mailto:contact@trouversoncadeau.com"}},[_vm._v("Contactez-nous")]),_c('router-link',{staticClass:"white--text",class:{
            'mr-8': _vm.$vuetify.breakpoint.smAndUp,
            'mt-4': _vm.$vuetify.breakpoint.xs
          },attrs:{"to":{ name: 'cgu' }}},[_vm._v("CGU")]),_c('span',{staticClass:"tsc-picto",class:{
            'mt-4': _vm.$vuetify.breakpoint.xs
          }}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('hr',{staticClass:"\n            tsc-separator\n            tsc-separator--light\n            mt-6\n          "}):_vm._e(),_c('span',{class:{
            'mt-6': _vm.$vuetify.breakpoint.xs
          }},[_vm._v("© "+_vm._s(_vm.year)+" Trouversoncadeau.com, tous droit réservé")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }