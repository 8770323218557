var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:{
    'mx-n3': _vm.$vuetify.breakpoint.xs
  },attrs:{"tag":"section","no-gutters":""}},[(_vm.config.home_bottom_ad_url)?_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 4 : 12,"order":_vm.flipped ? 2 : 1}},[_c('a',{attrs:{"href":_vm.config.home_bottom_ad_url,"rel":"noopener","target":"_blank","aria-label":"Home bottom ad"}},[_c('v-img',{attrs:{"src":_vm.config.home_bottom_ad_img,"alt":"home bottom ad","height":_vm.$vuetify.breakpoint.smAndUp ? '300px' : 'auto',"position":"left center","contain":""}})],1)]):_vm._e(),_c('v-col',{staticClass:"py-0",class:{
      'px-12': _vm.$vuetify.breakpoint.xs
    },attrs:{"cols":!_vm.config.home_bottom_ad_url || _vm.$vuetify.breakpoint.xs ? 12 : 8,"order":_vm.flipped ? 1 : 2}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:{
          'mt-12': !_vm.flipped && _vm.$vuetify.breakpoint.xs,
          'mb-12': _vm.flipped && _vm.$vuetify.breakpoint.xs
        }},[_c('h3',{staticClass:"\n            headline-2\n            text-center\n          "},[_vm._v("On vous aime déjà et vous ?")]),_c('div',{staticClass:"\n            text-center\n            my-6\n          "},[_c('a',{staticClass:"\n              mx-4\n              tsc-picto-social\n            ",attrs:{"href":"https://www.facebook.com/Cadeau/","target":"_blank","rel":"noopener noreferrer","aria-label":"Notre page Facebook"}},[_c('fa-icon',{staticClass:"white--text",attrs:{"icon":['fab', 'facebook-f'],"fixed-width":""}})],1),_c('a',{staticClass:"\n              mx-4\n              tsc-picto-social\n            ",attrs:{"href":"https://twitter.com/trouvercadeau","target":"_blank","rel":"noopener noreferrer","aria-label":"Notre page Twitter"}},[_c('fa-icon',{staticClass:"white--text",attrs:{"icon":['fab', 'twitter'],"fixed-width":""}})],1),_c('span',{staticClass:"\n              mx-4\n              tsc-picto-social\n            "},[_c('fa-icon',{staticClass:"white--text",attrs:{"icon":['fab', 'instagram'],"fixed-width":""}})],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }