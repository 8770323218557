<template>
  <span
    class="hamburger"
    :class="{
      'hamburger--active': active
    }"
  >
    <span class="hamburger-bar"></span>
  </span>
</template>

<script>

export default {
  name: 'Hamburger',
  props: {
    active: Boolean
  }
}
</script>

<style lang="sass">
.hamburger
  position: relative
  width: 22px
  height: 22px

  &:before,
  &:after
    content: ''
    position: absolute
    left: 0
    height: 4px
    width: 100%
    border-radius: 2px
    transition: .3s

  &:before
    background: var(--v-accent-base)
    top: 0
    z-index: 1

  &:after
    background: var(--v-primary-base)
    top: 8px

  &--active
    &:before,
    &:after
      width: 30px
      top: 9px
      left: -4px
      height: 5px
      border-radius: 2.5px

    &:before
      transform: rotate(45deg)

    &:after
      transform: rotate(-45deg)

    .hamburger-bar
      opacity: 0

.hamburger-bar
  transition: .2s

  &:after
    content: ''
    position: absolute
    left: 0
    height: 4px
    width: 100%
    border-radius: 2px
    background: var(--v-primary-base)
    top: 16px
</style>
