<template>
  <v-row
    tag="footer"
  >
    <v-col
      class="
        py-0
        primary--text
      "
    >
      <h3
        class="mb-6"
        :class="{
          'headline-2': $vuetify.breakpoint.smAndUp,
          'headline font-weight-medium': $vuetify.breakpoint.xs
        }"
        v-html="config.home_footer_title"
      ></h3>

      <div
        :class="{
          'body-2': $vuetify.breakpoint.xs
        }"
        v-html="config.home_footer_text"
      ></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeFooter',
  computed: {
    ...mapState(['config'])
  }
}
</script>
