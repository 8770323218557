<template>
  <article v-if="page">
    <v-row
      tag="header"
      align="center"
    >
      <v-col
        :cols="page.banner && $vuetify.breakpoint.smAndUp ? 5 : 12"
        tag="h1"
        class="
          display-1
          page-title
          font-weight-light
          primary--text
        "
      >
        <span v-html="page.title"></span>
        <span class="ml-4 tsc-picto"></span>
      </v-col>

      <v-col
        v-if="page.banner"
        :cols="$vuetify.breakpoint.smAndUp ? 7 : 12"
      >
        <v-img
          :src="page.banner"
          max-height="300"
        ></v-img>
      </v-col>
    </v-row>

    <v-row
      v-if="false && page.text_top"
      class="body-1"
      tag="h2"
    >
      <v-col
        v-html="page.text_top"
      ></v-col>
    </v-row>

    <v-row
      tag="section"
      :class="{
        'mt-9': $vuetify.breakpoint.lgAndUp,
        'mt-3': $vuetify.breakpoint.xs
      }"
    >
      <v-col
        v-for="product in products"
        :key="product.id"
        :cols="$vuetify.breakpoint.xs ? 6 : 3"
        tag="article"
        :class="{
          'py-9': $vuetify.breakpoint.smAndUp,
          'py-5': $vuetify.breakpoint.xs
        }"
      >
        <Product :product="product"></Product>
      </v-col>
    </v-row>

    <LoadMoreProducts
      class="mt-9"
    ></LoadMoreProducts>

    <SocialBlock
      class="mt-12"
      flipped
    ></SocialBlock>

    <v-row
      v-if="page.text_bottom"
      tag="footer"
      class="mt-12"
    >
      <v-col>
        <hr class="tsc-separator mb-12">

        <h2
          v-if="page.title_bottom"
          class="mb-6"
          :class="{
            'headline-2': $vuetify.breakpoint.smAndUp,
            'headline font-weight-medium': $vuetify.breakpoint.xs
          }"
          v-html="page.title_bottom"
        ></h2>

        <div
          :class="{
            'body-2': $vuetify.breakpoint.xs
          }"
          v-html="page.text_bottom"
        ></div>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import Product from '../components/Product.vue'
import LoadMoreProducts from '../components/LoadMoreProducts.vue'
import SocialBlock from '../components/SocialBlock.vue'

export default {
  components: {
    Product,
    LoadMoreProducts,
    SocialBlock
  },
  computed: {
    ...mapState([
      'page',
      'products'
    ])
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.uri !== from.params.uri) {
      this.$store.commit('resetProducts')
      await this.loadProducts(to.params.uri)
    }
    next()
  },
  async created() {
    await this.loadProducts(this.$route.params.uri)
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  },
  methods: {
    async loadProducts(uri) {
      await this.$store.dispatch('getPages')
      if (Array.isArray(uri)) uri = uri.join('/')
      this.$store.commit('setPage', uri)
      if (this.page) {
        this.$seo.update({
          title: this.$filters.striptags(this.page.html_title || this.page.title),
          metaDescription: this.page.meta_description
        })
      } else {
        this.$router.push({ name: 'not-found' })
        return
      }
      await this.$store.dispatch('loadMoreProducts')
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetProducts')
    next()
  }
}
</script>
