<template>
  <v-card
    elevation="0"
    class="
      d-flex
      flex-column
    "
    height="100%"
    :ripple="false"
    :href="product.url | decodeUri"
    @click.prevent="openProduct(product)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-img
      lazy-src="/placeholder.svg"
      :src="product.image || 'placeholder.svg'"
      :transition="false"
      contain
      :alt="product.title"
      :height="$vuetify.breakpoint.xs ? '160px': '192px'"
    ></v-img>

    <v-card-text
      class="
        px-0
        pb-0
        secondary--text
        fill-height
        d-flex
        flex-wrap
        align-content-space-between
      "
      :class="{
        'subtitle-1': $vuetify.breakpoint.smAndUp,
        'body-2': $vuetify.breakpoint.xs
      }"
    >
      <div
        style="width: 100%"
        class="text-center"
      >{{ product.title }}</div>

      <span
        class="
          product-chip
          mt-4
          mx-auto
          d-inline-flex
          primary--text
        "
        :class="{
          'product-chip--hover': hover
        }"
      >
        <span
          class="product-chip__text"
          :style="{ opacity: hover ? 0 : 1 }"
        >{{ product.price | monetize('EUR') }}</span>

        <span
          class="product-chip__text"
          :style="{ opacity: hover ? 1 : 0 }"
        >Voir le produit</span>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Product',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    openProduct(product) {
      const routeData = this.$router.resolve({
        name: 'product',
        query: { id: product.id }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="sass">
.product-chip
  border-radius: 16px
  font-size: 14px
  height: 32px
  width: 84px
  vertical-align: middle
  white-space: nowrap
  position: relative
  transition: .3s
  overflow: hidden

  &:before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    width: 144px
    height: 144px
    margin: -72px 0 0 -72px
    background: linear-gradient(-90deg, var(--v-primary-base) 50%, var(--v-accent-base) 50%)
    background-size: 300%
    background-position: 100%
    transition: 1s cubic-bezier(0, .5, 1, .5)
    transform: rotate(0deg)

  &:after
    content: ''
    position: absolute
    top: 1px
    left: 1px
    bottom: 1px
    right: 1px
    border-radius: 15px
    background: white

  &__text
    transition: .3s
    position: absolute
    z-index: 1
    width: 100%
    text-align: center
    top: 50%
    margin-top: -.5em
    line-height: 1em

  &--hover
    width: 144px

    &:before
      background-position: 0
      transform: rotate(180deg)
</style>
