import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: null,
    drawer: false,
    menus: [],
    selectedMenus: [],
    version: null,
    currentPage: 0,
    total: 0,
    itemsLeft: 0,
    itemsPerPage: 60,
    brand: null,
    brands: [],
    brandsLoading: false,
    page: null,
    pages: [],
    pagesLoading: false,
    products: [],
    product: {},
    partner: {},
    deals: [],
    deal: {},
    dealDialog: false,
    cookiesDialog: false
  },
  mutations: {
    setBrands(state, brands = []) {
      state.brands = brands
    },
    setPages(state, pages = []) {
      state.pages = pages
    },
    setProducts(state, data) {
      data = data || {}
      const {
        products,
        currentPage,
        total,
        itemsLeft
      } = data
      state.total = total
      state.currentPage = currentPage || 0
      state.itemsLeft = itemsLeft || 0
      state.products = [...state.products, ...products]
    },
    setPage(state, uri) {
      state.page = state.pages.find(page => page.uri === uri)
    },
    setBrand(state, uri) {
      state.brand = state.brands.find(b => b.uri === uri)
    },
    resetProducts(state) {
      state.currentPage = 0
      state.total = 0
      state.itemsLeft = 0
      state.products = []
      state.page = null
      state.brand = null
    },
    setProduct(state, product = {}) {
      state.product = product
    },
    setPartner(state, partner = {}) {
      state.partner = partner
    },
    setDeals(state, deals = []) {
      state.deals = deals.map((deal) => {
        const types = deal.types.map(t => t.toLowerCase())
        const { value, currency, percentage } = deal.amount
        switch (true) {
          case types.includes('reduction'):
            deal.discount = percentage ? `${value}%` : Vue.$filters.monetize(value, currency)
            deal.shortDescription = `Réduction de ${deal.discount}`
            deal.type = 'discount'
            break
          case types.includes('delivery'):
            deal.shortDescription = 'Livraison offerte'
            deal.type = 'delivery'
            break
          default:
            deal.shortDescription = 'Bon plan'
            deal.type = 'deal'
        }
        return deal
      })
    },
    setDealDialog(state, { deal, dialog } = {}) {
      state.deal = deal || {}
      state.dealDialog = dialog
    },
    setCookiesDialog(state, { dialog } = {}) {
      state.cookiesDialog = dialog
    },
    setConfig(state, config) {
      state.config = config || {}
    },
    setVersion(state, version) {
      state.version = version
    },
    setDrawer(state, value) {
      state.drawer = value
    },
    initSelectedMenus(state, intialValue) {
      state.selectedMenus = intialValue
    }
  },
  actions: {
    async getBrands({ commit, state }) {
      if (state.brandsLoading) return
      state.brandsLoading = true
      const response = await Vue.axios.get('/public/brands')
      commit('setBrands', response.data.data)
    },
    async getPages({ commit, state }) {
      if (state.pagesLoading) return
      state.pagesLoading = true
      const response = await Vue.axios.get('/public/pages')
      commit('setPages', response.data.data)
    },
    async loadMoreProducts({ commit, state }) {
      let url
      switch (true) {
        case !!state.page:
          url = `page/${state.page.id}/products`
          break
        case !!state.brand:
          url = `brand/${state.brand.id}/products`
          break
        default: return
      }
      const params = {
        itemsPerPage: state.itemsPerPage,
        page: state.currentPage + 1
      }
      const response = await Vue.axios.get(`/public/${url}`, { params })
      commit('setProducts', {
        products: response.data.data,
        currentPage: response.data.page,
        itemsLeft: response.data.itemsLeft,
        total: response.data.total
      })
    },
    async getProduct({ commit }, id) {
      const response = await Vue.axios.get(`/public/product/${id}`)
      commit('setProduct', response.data.data)
    },
    async loadAllDeals({ commit }) {
      const response = await Vue.axios.get('/public/deals')
      commit('setDeals', response.data.data)
    },
    async loadBrandDeals({ commit, state }) {
      const response = await Vue.axios.get(`/public/brand/${state.brand.id}/partner`)
      commit('setDeals', response.data.data.coupons)
      commit('setPartner', response.data.data)
    },
    async getConfig({ commit }) {
      const response = await Vue.axios.get('/public/config')
      commit('setConfig', response.data.data)
    },
    async getMenus({ state }) {
      const response = await Vue.axios.get('/public/menus')
      const menus = response.data.data

      function sortByOrder(a, b) {
        return a.order < b.order ? -1 : 1
      }

      function findChildren(arr, level = 0) {
        const res = arr.reduce((lvl, menu) => {
          menu.hover = false
          menu.children = menus.filter(m => m.parent_id === menu.id)
          menu.nbLevels = menu.children.length
            ? findChildren(menu.children, level + 1)
            : lvl
          return menu.nbLevels
        }, level)
        arr.sort(sortByOrder)
        return res
      }

      const level0 = menus.filter(m => !m.parent_id)
      findChildren(level0)
      state.menus = level0
    },
    async getVersion({ commit }) {
      const { data } = await Vue.axios.get('/')
      commit('setVersion', data)
    }
  }
})
