<template>
  <v-row
    v-if="target"
    align="center"
    tag="header"
    :class="{
      'mx-n3': $vuetify.breakpoint.xs
    }"
    :no-gutters="$vuetify.breakpoint.xs"
  >
    <v-col
      :cols="$vuetify.breakpoint.smAndUp && config.home_banner_ad_url ? 9 : 12"
    >
      <router-link
        :to="{ name: 'page', params: { uri: target.split('/') } }"
      >
        <v-img
          :src="$vuetify.breakpoint.smAndUp ? config.home_banner : config.home_banner_small"
          :alt="config.home_banner_text | striptags"
          :height="$vuetify.breakpoint.smAndUp ? 300 : 450"
        >
          <v-row
            :align="$vuetify.breakpoint.smAndUp ? 'center' : 'start'"
            class="fill-height"
          >
            <v-col
              :cols="$vuetify.breakpoint.smAndUp ? 4 : 7"
              offset="1"
              class="
                d-flex
                flex-column
                align-start
              "
              :class="{
                'mt-4': $vuetify.breakpoint.xs
              }"
            >
              <h2
                class="white--text"
                :class="{
                  'display-1 font-weight-medium': $vuetify.breakpoint.mdAndUp,
                  'headline-2': $vuetify.breakpoint.smAndDown
                }"
              >
                <span v-html="config.home_banner_text"></span>
                <span class="tsc-picto d-inline"></span>
              </h2>

              <v-btn
                rounded
                depressed
                color="accent"
                class="mt-6"
              >Voir la sélection</v-btn>
            </v-col>
          </v-row>
        </v-img>
      </router-link>
    </v-col>

    <v-col
      cols="3"
      v-if="
        $vuetify.breakpoint.smAndUp
        && config.home_banner_ad_url
      "
    >
      <a
        :href="config.home_banner_ad_url"
        rel="noopener"
        target="_blank"
        aria-label="Home banner ad"
      >
        <v-img
          :src="config.home_banner_ad_img"
          alt="home banner ad"
          height="300px"
          position="right center"
          contain
        ></v-img>
      </a>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeBanner',
  computed: {
    ...mapState([
      'pages',
      'config'
    ]),
    target() {
      const page = this.pages.find(p => p.id.toString() === this.config.home_banner_page)
      if (!page) return null
      return page.uri
    }
  },
  async created() {
    await this.$store.dispatch('getPages')
  }
}
</script>
