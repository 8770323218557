<template>
  <router-link
    v-if="page"
    class="
      d-block
      page-highlight-block
    "
    :to="{ name: 'page', params: { uri: page.uri.split('/') } }"
  >
    <v-img
      :src="page.banner_small"
    >
      <v-row
        class="fill-height"
        align="end"
        justify="center"
        no-gutters
      >
        <v-col
          cols="10"
          class="text-center"
        >
          <v-chip
            :x-large="$vuetify.breakpoint.lgAndUp"
            :large="$vuetify.breakpoint.mdAndDown"
            color="white"
            text-color="accent"
            class="
              font-weight-medium
              d-block
              mb-n4
              text-wrap
            "
            disabled
          >{{ title }}</v-chip>
        </v-col>
      </v-row>
    </v-img>
  </router-link>
</template>

<script>
export default {
  name: 'PagesHighligthBlock',
  props: {
    page: Object
  },
  computed: {
    title() {
      return this.page.title_short || this.$filters.striptags(this.page.title)
    }
  }
}
</script>

<style lang="sass">
.page-highlight-block
  .v-chip--disabled
    opacity: 1
</style>
