<template>
  <article
    class="brand"
    v-if="ready"
  >
    <DealDialog></DealDialog>

    <v-row
      tag="header"
      align="center"
    >
      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 5 : 12"
      >
        <h1
          class="
            display-1
            brand-name
          "
        >
          <span>Bons plans</span>
          <br>
          <strong>{{ brand.name }}</strong>
          <i class="ml-4 tsc-picto"></i>
        </h1>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 7 : 12"
      >
        <v-img
          :src="
            $vuetify.breakpoint.smAndUp
            ? (brand.banner || config.deals_banner)
            : (brand.banner_small || config.deals_banner_small)
          "
          max-height="300"
        ></v-img>
      </v-col>
    </v-row>

    <v-row
      tag="section"
      class="mt-12"
    >
      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 6 : 12"
      >
        <div class="brand-insert">
          <div
            class="
              brand-insert__content
              pa-6
              d-flex
              flex-column
            "
          >
            <v-row align="center">
              <v-col
                :cols="$vuetify.breakpoint.smAndUp ? 5 : 12"
              >
                <v-img
                  :src="brand.image"
                ></v-img>
              </v-col>

              <v-col
                :cols="$vuetify.breakpoint.smAndUp ? 7 : 12"
              >
                <h2 class="headline font-weight-medium">{{ brand.name }}</h2>
                <h3 class="headline">Codes promos & bons plans</h3>
              </v-col>
            </v-row>

            <div class="mt-2">
              <h4 class="title font-weight-regular mb-2"><strong>{{ deals.length }}</strong> offres disponibles</h4>
              <h5 class="subtitle-2">À propos de {{ brand.name }}</h5>
              <div
                class="body-2"
                v-line-clamp:5.block-action
                v-html="brand.about || partner.description"
              ></div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 3 : 12"
        v-for="deal in deals"
        :key="deal.id"
        tag="article"
      >
        <Deal :deal="deal"></Deal>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import Deal from '../components/Deal.vue'
import DealDialog from '../components/DealDialog.vue'

export default {
  components: {
    Deal,
    DealDialog
  },
  data() {
    return {
      ready: false
    }
  },
  computed: {
    ...mapState([
      'brand',
      'deals',
      'partner',
      'config'
    ])
  },
  async created() {
    await this.$store.dispatch('getBrands')
    this.$store.commit('setBrand', this.$route.params.uri)
    if (this.brand) {
      this.$seo.update({
        title: `${this.$filters.striptags(this.brand.name)} | Codes promos & bons plans`,
        metaDescription: `Retoruver tous les bons plans et codes promos de ${this.brand.name} sur TrouverSonCadeau.com.`
      })
    } else {
      this.$router.push({ name: 'not-found' })
      return
    }

    await this.$store.dispatch('loadBrandDeals')
    this.ready = true
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setDeals', [])
    this.$store.commit('setPartner', {})
    next()
  }
}
</script>

<style lang="sass">
.brand-insert
  background: #fafafa
  border: 1px solid #dedede
  border-radius: 0 40px

  .show-more,
  .show-less
    display: inline-block
    position: relative
    padding: 10px 25px 5px 0

    &:before
      content: ''
      height: 6px
      width: 18px
      border-radius: 3px
      background: var(--v-primary-base)
      position: absolute
      right: 0
      bottom: 0

    &:after
      content: ''
      height: 18px
      width: 6px
      border-radius: 3px
      background: var(--v-accent-base)
      position: absolute
      right: 0
      bottom: 0

  &,
  &__content
    height: 100%
    position: relative

    &:after
      content: ''
      height: 8px
      width: 45px
      border-radius: 4px
      background: var(--v-primary-base)
      position: absolute
      z-index: 1

    &:before
      content: ''
      height: 45px
      width: 8px
      border-radius: 4px
      background: var(--v-accent-base)
      position: absolute
      z-index: 1

  &
    &:after
      left: -4px
      top: -4px

    &:before
      left: -4px
      top: -4px

  &__content
    &:after
      right: -4px
      bottom: -4px

    &:before
      right: -4px
      bottom: -4px
</style>
