<template>
  <v-fade-transition>
    <div
      v-if="drawer"
      class="tsc-mobile-menu"
    >
      <div
        v-if="selectedMenus[1]"
        @click="goToParent()"
        class="
          py-2
          pr-6
          font-weight-regular
          float-right
        "
        :class="{
          'title': $vuetify.breakpoint.smAndUp,
          'subtitle-1': $vuetify.breakpoint.xs
        }"
      >
        <span
          class="
            tsc-menu-picto
            tsc-menu-picto--left-dir
          "
        ></span>

        <span
          class="grey--text"
        >Retour</span>
      </div>

      <div
        v-if="selectedMenus[1]"
        class="py-2 title"
      >
        <span
          class="
            my-n2
            py-2
            pl-8
            pr-9
            primary
            white--text
            text-no-wrap
          "
          style="
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px
          "
        >
          {{ selectedMenus[0].name }}
          <span class="tsc-picto d-inline"></span>
        </span>
      </div>

      <div
        class="pb-3 mt-2"
        v-if="selectedMenus[0].page_uri"
      >
        <router-link
          :to="{ name: 'page', params: { uri: selectedMenus[0].page_uri.split('/') } }"
          class="
            pl-8
            pb-3
            tsc-menu-back-link
            font-weight-medium
          "
        >Voir tout</router-link>
      </div>

      <div
        v-for="child in selectedMenus[0].children"
        :key="child.id"
        class="px-8 py-2"
        :class="{
          'title': !selectedMenus[1],
          'subtitle-1': selectedMenus[1]
        }"
      >
        <router-link
          v-if="child.page_uri && !child.children.length"
          :to="{ name: 'page', params: { uri: child.page_uri.split('/') } }"
        >{{ child.name }}</router-link>

        <span
          v-if="!child.page_uri || child.children.length"
          @click="selectMenu(child)"
        >
          {{ child.name }}
          <span class="tsc-menu-picto"></span>
        </span>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuMobile',
  computed: {
    ...mapState([
      'drawer',
      'menus',
      'selectedMenus'
    ])
  },
  created() {
    this.init()
  },
  watch: {
    drawer(value) {
      window.scrollTo(0, 0)
      document.querySelector('html').style.overflow = value ? 'hidden' : null
      if (value) return
      this.init()
    },
    $route() {
      this.$store.commit('setDrawer', false)
    }
  },
  methods: {
    init() {
      this.$store.commit('initSelectedMenus', [{ children: this.menus }])
    },
    selectMenu(menu) {
      this.selectedMenus.unshift(menu)
    },
    goToParent() {
      this.selectedMenus.shift()
    }
  }
}
</script>

<style lang="sass">
.tsc-mobile-menu
  position: fixed
  left: 0
  top: 120px
  bottom: 0
  width: 100%
  background: white
  z-index: 2
  overflow-y: scroll

.tsc-menu-picto
  width: 12px
  height: 12px
  position: relative
  display: inline-block
  vertical-align: middle
  transform: rotate(-45deg)
  margin-left: 12px

  &--left-dir
    transform: rotate(135deg)
    margin-right: 4px
    margin-left: 0

  &:before
    content: ''
    position: absolute
    width: 12px
    height: 4px
    border-radius: 2px
    background: var(--v-primary-base)
    bottom: 0
    left: 0

  &:after
    content: ''
    position: absolute
    width: 4px
    height: 12px
    border-radius: 2px
    background: var(--v-accent-base)
    right: 0
    top: 0

.tsc-menu-back-link
  position: relative

  &:after
    content: ''
    position: absolute
    bottom: 0
    right: 0
    width: 100%
    background: radial-gradient(var(--v-primary-base) 40%, transparent 40%) -10px -6px
    background-size: 30px 16px
    background-color: var(--v-accent-base)
    height: 4px
    border-radius: 2px
    border: 0
</style>
