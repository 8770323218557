<template>
  <v-row
    tag="section"
    :class="{
      'mx-n3': $vuetify.breakpoint.xs
    }"
    no-gutters
  >
    <v-col
      :cols="$vuetify.breakpoint.smAndUp ? 4 : 12"
      class="py-0"
      v-if="config.home_bottom_ad_url"
      :order="flipped ? 2 : 1"
    >
      <a
        :href="config.home_bottom_ad_url"
        rel="noopener"
        target="_blank"
        aria-label="Home bottom ad"
      >
        <v-img
          :src="config.home_bottom_ad_img"
          alt="home bottom ad"
          :height="$vuetify.breakpoint.smAndUp ? '300px' : 'auto'"
          position="left center"
          contain
        ></v-img>
      </a>
    </v-col>

    <v-col
      :cols="!config.home_bottom_ad_url || $vuetify.breakpoint.xs ? 12 : 8"
      class="py-0"
      :class="{
        'px-12': $vuetify.breakpoint.xs
      }"
      :order="flipped ? 1 : 2"
    >
      <v-row
        no-gutters
        class="fill-height"
        align="center"
      >
        <v-col
          :class="{
            'mt-12': !flipped && $vuetify.breakpoint.xs,
            'mb-12': flipped && $vuetify.breakpoint.xs
          }"
        >
          <h3
            class="
              headline-2
              text-center
            "
          >On vous aime déjà et vous ?</h3>

          <div
            class="
              text-center
              my-6
            "
          >
            <a
              href="https://www.facebook.com/Cadeau/"
              target="_blank"
              rel="noopener noreferrer"
              class="
                mx-4
                tsc-picto-social
              "
              aria-label="Notre page Facebook"
            >
              <fa-icon
                :icon="['fab', 'facebook-f']"
                fixed-width
                class="white--text"
              ></fa-icon>
            </a>

            <a
              href="https://twitter.com/trouvercadeau"
              target="_blank"
              rel="noopener noreferrer"
              class="
                mx-4
                tsc-picto-social
              "
              aria-label="Notre page Twitter"
            >
              <fa-icon
                :icon="['fab', 'twitter']"
                fixed-width
                class="white--text"
              ></fa-icon>
            </a>

            <span
              class="
                mx-4
                tsc-picto-social
              "
            >
              <fa-icon
                :icon="['fab', 'instagram']"
                fixed-width
                class="white--text"
              ></fa-icon>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeFooter',
  props: {
    flipped: Boolean
  },
  computed: {
    ...mapState(['config'])
  }
}
</script>

<style lang="sass">
.tsc-picto-social
  position: relative
  display: inline-block
  width: 20px
  height: 16px
  font-size: 16px
  line-height: 1em

  & > *
    position: relative
    z-index: 1

  &:after
    content: ''
    position: absolute
    width: 32px
    height: 32px
    border-radius: 50%
    background: var(--v-accent-base)
    top: -8px
    left: -6px
    z-index: 0

  &:before
    content: ''
    position: absolute
    width: 0
    height: 0
    border-style: solid
    border-color: transparent
    border-width: 26px 10px 0 10px
    border-top-color: var(--v-accent-base)
    left: -5px
    top: 16px
    transform: rotate(15deg)
    z-index: 0
</style>
