<template>
  <v-row
    tag="section"
    class="pages-highlight"
    v-if="pageIds.length === 4"
  >
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 5 : ($vuetify.breakpoint.smAndUp ? 6 : 12)"
      :order="$vuetify.breakpoint.mdAndUp ? 0 : 2"
      class="py-0"
    >
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 6 : 12"
        >
          <PageHighlightBlock
            :page="_pages[0]"
          ></PageHighlightBlock>
        </v-col>

        <v-col
          cols="6"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <PageHighlightBlock
            :page="_pages[1]"
          ></PageHighlightBlock>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 2 : 9"
      :order="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      class="
        py-0
        d-flex
        align-center
      "
    >
      <v-container
        class="pa-0"
        :class="{
          'pages-highlight__title-container': $vuetify.breakpoint.mdAndUp
        }"
        fluid
      >
        <v-row
          class="
            pages-highlight__title
            d-inline-block
          "
          :class="{
            'pr-8 pb-5 mb-2 mt-4': $vuetify.breakpoint.smAndDown
          }"
          no-gutters
        >
          <v-col>
            <h3
              class="primary--text"
              :class="{
                'py-12 px-3': $vuetify.breakpoint.mdAndUp,
                'headline-2': $vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smAndDown,
                'headline font-weight-medium': $vuetify.breakpoint.md
              }"
            >Nos meilleures catégories</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 5 : ($vuetify.breakpoint.smAndUp ? 6 : 12)"
      order="3"
      class="py-0"
    >
      <v-row
        class="flex-nowrap"
        :style="{
          overflow: $vuetify.breakpoint.xs ? 'scroll' : null
        }"
      >
        <v-col
          cols="9"
          v-if="$vuetify.breakpoint.xs"
        >
          <PageHighlightBlock
            :page="_pages[1]"
          ></PageHighlightBlock>
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 6 : 9"
        >
          <PageHighlightBlock
            :page="_pages[2]"
          ></PageHighlightBlock>
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 6 : 9"
        >
          <PageHighlightBlock
            :page="_pages[3]"
          ></PageHighlightBlock>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import PageHighlightBlock from './PageHighlightBlock.vue'

export default {
  name: 'PagesHighligth',
  components: {
    PageHighlightBlock
  },
  props: {
    pageIds: Array
  },
  computed: {
    ...mapState(['pages']),
    _pages() {
      return this.pageIds.map(id => this.pages.find(p => p.id.toString() === id))
    }
  },
  async created() {
    await this.$store.dispatch('getPages')
  }
}
</script>

<style lang="sass">
.pages-highlight
  &__title-container,
  &__title
    position: relative

    &:after
      content: ''
      height: 8px
      width: 45px
      border-radius: 4px
      background: var(--v-primary-base)
      position: absolute

    &:before
      content: ''
      height: 45px
      width: 8px
      border-radius: 4px
      background: var(--v-accent-base)
      position: absolute

  &__title-container
    &:after
      left: 0
      top: 0

    &:before
      left: 0
      top: 0

  &__title
    &:after
      right: 0
      bottom: 0

    &:before
      right: 0
      bottom: 0
</style>
