<template>
  <v-footer
    class="
      tsc-app__footer
      white--text
      font-weight-bold
      pa-0
      mt-12
    "
    color="primary"
  >
    <v-container
      :class="{
        'py-12': $vuetify.breakpoint.smAndUp,
        'py-6': $vuetify.breakpoint.xs
      }"
    >
      <v-row>
        <v-col
          class="d-flex py-0"
          :class="{
            'flex-column px-6': $vuetify.breakpoint.xs,
            'align-center': $vuetify.breakpoint.smAndUp
          }"
        >
          <v-img
            v-if="$vuetify.breakpoint.xs"
            src="/logo-icon-white.svg"
            alt="Trouver son cadeau logo small"
            style="position: absolute; right: 24px; top: 24px"
            width="40px"
          ></v-img>

          <router-link
            :to="{ name: 'a-propos' }"
            class="white--text mr-8"
          >À propos</router-link>

          <a
            href="mailto:contact@trouversoncadeau.com"
            class="white--text"
            :class="{
              'mr-8': $vuetify.breakpoint.smAndUp,
              'mt-4': $vuetify.breakpoint.xs
            }"
          >Contactez-nous</a>

          <router-link
            :to="{ name: 'cgu' }"
            class="white--text"
            :class="{
              'mr-8': $vuetify.breakpoint.smAndUp,
              'mt-4': $vuetify.breakpoint.xs
            }"
          >CGU</router-link>

          <span
            class="tsc-picto"
            :class="{
              'mt-4': $vuetify.breakpoint.xs
            }"
          ></span>

          <v-spacer
            v-if="$vuetify.breakpoint.smAndUp"
          ></v-spacer>

          <hr
            class="
              tsc-separator
              tsc-separator--light
              mt-6
            "
            v-if="$vuetify.breakpoint.xs"
          />

          <span
            :class="{
              'mt-6': $vuetify.breakpoint.xs
            }"
          >&copy; {{ year }} Trouversoncadeau.com, tous droit réservé</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
