<template>
  <article
    class="brand"
    v-if="ready"
  >
    <DealDialog></DealDialog>

    <v-row
      tag="header"
    >
      <v-col
        :cols="deals.length ? 8 : 12"
      >
        <h1
          class="
            display-1
            brand-name
          "
        >{{ brand.name }}</h1>
      </v-col>

      <v-col
        cols="4"
        align-self="end"
        v-if="deals.length && products.length"
      >
        <h2
          class="headline"
        >Bons plans</h2>
      </v-col>
    </v-row>

    <v-row tag="section">
      <v-col
        :cols="deals.length ? 8 : 12"
        v-if="products.length"
        tag="article"
      >
        <v-container
          pa-0
          fluid
          tag="section"
        >
          <v-row>
            <v-col
              v-for="product in products"
              :key="product.id"
              :cols="deals.length ? 4 : 3"
              tag="article"
            >
              <Product :product="product"></Product>
            </v-col>
          </v-row>

          <v-row justify="center">
            <LoadMoreProducts></LoadMoreProducts>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        :cols="products.length ? 4 : 12"
        v-if="deals.length"
        tag="article"
      >
        <v-container
          pa-0
          fluid
          tag="section"
        >
          <v-row>
            <v-col
              :cols="products.length ? 12 : 3"
              v-for="deal in deals"
              :key="deal.id"
              tag="article"
            >
              <Deal :deal="deal"></Deal>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import Product from '../components/Product.vue'
import LoadMoreProducts from '../components/LoadMoreProducts.vue'
import Deal from '../components/Deal.vue'
import DealDialog from '../components/DealDialog.vue'

export default {
  components: {
    Product,
    LoadMoreProducts,
    Deal,
    DealDialog
  },
  data() {
    return {
      ready: false
    }
  },
  computed: {
    ...mapState([
      'brand',
      'products',
      'deals'
    ])
  },
  async created() {
    await this.$store.dispatch('getBrands')
    this.$store.commit('setBrand', this.$route.params.uri)
    if (this.brand) {
      this.$seo.update({
        title: this.$filters.striptags(this.brand.name),
        metaDescription: `Trouvez le cadeau de la marque ${this.brand.name} sur TrouverSonCadeau.com`
      })
    } else {
      this.$router.push({ name: 'not-found' })
      return
    }

    await this.$store.dispatch('loadMoreProducts')
    await this.$store.dispatch('loadBrandDeals')
    this.ready = true
    setTimeout(() => { document.dispatchEvent(new Event('prerender-event')) }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetProducts')
    this.$store.commit('setDeals', [])
    next()
  }
}
</script>
