<template>
  <article
    class="
      about
      my-12
    "
  >
    <h1 class="display-1 mb-12">À propos</h1>

    <p>
      Trouversoncadeau.com désigne le site internet d'aide à la recherche de cadeaux.
    </p>

    <p>
      TrouverSonCadeau.com est un service proposé par la société Ifeelgoods SAS, société à
      responsabilité limitée au capital social de 130 000 €, ayant son siège social 92044,
      Paris la Défense Cédex, France (téléphone : 01 73 79 20 28), immatriculée sous le numéro
      532-276-102-00011 au RCS de Paris et dont le numéro de TVA intracommunautaire est FR59532276102.
    </p>

    <p>
      Le Directeur de la publication de ce site est Dimitri Ducourtieux en qualité de gérant de IFEELGOODS SAS.
    </p>

    <p>
      Coordonnées de l'hébergeur du site :<br>
      IDIANET, 37 Rue Truffaut, 75017 Paris<br>
      Site : www.idianet.net/site
    </p>
  </article>
</template>

<script>
export default {
  created() {
    this.$seo.update({
      title: 'À propos'
    })
    document.dispatchEvent(new Event('prerender-event'))
  }
}
</script>
