<template>
  <v-dialog
    v-model="dealDialog"
    max-width="500"
    overlay-color="white"
    content-class="deal-dialog"
    scrollable
  >
    <v-card
      flat
      tile
    >
      <v-card-text
        style="position: relative"
        v-perfect-scrollbar
        class="
          pa-6
          d-flex
          flex-column
          align-center
          text-center
        "
      >
        <span
          class="dialog-close"
          role="button"
          @click="dealDialog = false"
        ></span>

        <v-img
          v-if="deal.brandImage"
          :src="deal.brandImage"
          height="100px"
          max-height="100px"
          contain
          :alt="`Logo ${deal.partnerName}`"
        ></v-img>

        <div
          class="title"
          v-if="!deal.brandImage"
          style="line-height: 100px"
        >{{ deal.partnerName }}</div>

        <h3
          class="
            headline
            primary--text
            font-weight-bold
            mb-10
            mt-4
          "
        >{{ deal.shortDescription }}</h3>

        <div
          class="
            deal-code__container
            mb-10
          "
        >
          <div
            class="
              d-inline-block
              deal-code
              title
              primary--text
              py-3
              px-9
            "
            role="button"
            @click="copyCode($event)"
          >ABCD-1234-WXYZ</div>

          <v-fade-transition>
            <v-chip
              class="code-copied"
              v-show="copied"
              color="green accent-4"
              text-color="white"
              :style="{
                border: '1px solid rgba(0, 0, 0, 0.12) !important'
              }"
            >
              <fa-icon
                icon="check"
                fixed-width
                class="
                  white--text
                  mr-1
                "
                size="xs"
              ></fa-icon>
              Copié !
            </v-chip>
          </v-fade-transition>
        </div>

        <v-btn
          color="primary"
          depressed
          rounded
          small
          :href="deal.url"
          target="_blank"
          class="mb-10"
        >Lancer le site {{ deal.partnerName }}</v-btn>

        <div
          class="
            body-2
            grey--text
            text--darken-1
          "
          v-html="deal.description"
        ></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

let timeout

export default {
  name: 'DealDialog',
  data() {
    return {
      copied: false
    }
  },
  computed: {
    dealDialog: {
      get() { return this.$store.state.dealDialog },
      set(dialog) { this.$store.commit('setDealDialog', { dialog }) }
    },
    ...mapState(['deal'])
  },
  methods: {
    copyCode($event) {
      const range = document.createRange()
      const sel = window.getSelection()
      range.selectNode($event.target)
      sel.removeAllRanges()
      sel.addRange(range)
      document.execCommand('copy')
      sel.removeAllRanges()
      this.copied = true
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => { this.copied = false }, 3000)
    }
  }
}
</script>

<style lang="sass">
.deal-dialog
  border-radius: 40px !important

.deal-code
  border-top-right-radius: 10px
  border-bottom-left-radius: 10px
  background: #f9f9f9
  border: 1px solid #dedede

  &,
  &__container
    position: relative

    &:after
      content: ''
      height: 7px
      width: 30px
      border-radius: 4px
      background: var(--v-primary-base)
      position: absolute
      z-index: 1

    &:before
      content: ''
      height: 30px
      width: 7px
      border-radius: 4px
      background: var(--v-accent-base)
      position: absolute
      z-index: 1

  &:after
    right: -3px
    bottom: -3px

  &:before
    right: -3px
    bottom: -3px

  &__container
    &:after
      left: -3px
      top: -3px

    &:before
      left: -3px
      top: -3px

.code-copied
  position: absolute
  top: -16px
  right: -60px
</style>
