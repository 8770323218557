import PerfectScrollbar from 'perfect-scrollbar'

import 'perfect-scrollbar/css/perfect-scrollbar.css'

const PerfectScrollbarPlugin = {
  install(Vue) {
    Vue.directive('perfect-scrollbar', (el, value, vnode) => {
      vnode.context.$nextTick(() => {
        if (value === false || el.classList.contains('ps')) return
        // eslint-disable-next-line no-new
        new PerfectScrollbar(el)
      })
    })
  }
}

export default PerfectScrollbarPlugin
