<template>
  <v-app
    style="background: white"
    class="tsc-app"
  >
    <v-container
      fluid
      class="pa-0"
      style="z-index: 2; background: white"
    >
      <v-container class="py-0">
        <v-app-bar
          class="tsc-app__app-bar"
          elevation="0"
          :height="$vuetify.breakpoint.smAndDown ? 120 : 160"
        >
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            v-if="$vuetify.breakpoint.smAndDown"
            aria-label="toggle-menu-button"
          >
            <Hamburger
              :active="drawer"
            ></Hamburger>
          </v-app-bar-nav-icon>

          <v-spacer
            v-if="$vuetify.breakpoint.smAndDown"
          ></v-spacer>

          <router-link
            :to="{ name: 'home' }"
            class="d-block pr-12"
            aria-label="Trouver son cadeau - accueil"
          >
            <v-img
              src="/logo.svg"
              alt="Trouver son cadeau logo"
              :width="$vuetify.breakpoint.mdAndUp ? '198px' : '160px'"
            />
          </router-link>

          <v-spacer
            v-if="$vuetify.breakpoint.smAndDown"
          ></v-spacer>

          <v-toolbar-items
            v-if="
              menus.length
              && $vuetify.breakpoint.mdAndUp
            "
            @mouseleave="mouseleave($event)"
            class="menu-mouseleave-target"
          >
            <v-btn
              v-for="menu in menus"
              :key="menu.id"
              text
              :ripple="false"
              v-bind="{
                to: menu.page_uri && { name: 'page', params: { uri: menu.page_uri.split('/') } }
              }"
              active-class="active"
              exact
              @mouseenter="
                selectedMenu[0] = menu.children.length ? menu : null,
                selectedMenu[1] = menu.children.length ? menu.children[0] : null
              "
              class="menu-button"
              :class="{
                'active': selectedMenu[0] && selectedMenu[0].id === menu.id
              }"
            >
              <span>{{ menu.name }}</span>

              <span
                class="
                  ml-2
                  tsc-picto
                "
              ></span>
            </v-btn>

            <!-- <v-btn
              text
              :to="{ name: 'brands' }"
            >Nos marques</v-btn> -->

            <!-- <v-btn
              text
              :ripple="false"
              :to="{ name: 'deals' }"
              active-class="active"
              exact
              class="menu-button"
              @mouseenter="selectedMenu[0] = null"
            >
              Bons plans

              <span
                class="
                  ml-2
                  tsc-picto
                "
              ></span>
            </v-btn> -->
          </v-toolbar-items>
        </v-app-bar>
      </v-container>
    </v-container>

    <MenuMobile
      v-if="menus.length"
    ></MenuMobile>

    <Menu
      :selectedMenu="selectedMenu"
      @mouseleave="mouseleave($event)"
    ></Menu>

    <v-overlay
      z-index="1"
      :value="!!selectedMenu[0]"
    >
      <div
        class="d-flex"
        style="width: 100vw; height: 100vh"
        @click="leaveMenu()"
      ></div>
    </v-overlay>

    <v-content
      class="tcs-app__content"
      v-if="config"
    >
      <v-container>
        <router-view/>
      </v-container>
    </v-content>

    <Footer></Footer>

    <CookiesPanel></CookiesPanel>
    <CookiesDialog></CookiesDialog>

    <span
      style="position: absolute; bottom: 0; right: 0; color: rgba(255,255,255,.2)"
      class="caption"
      v-if="version"
    >{{ builtVersion }}</span>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import Menu from './components/Menu.vue'
import MenuMobile from './components/MenuMobile.vue'
import Footer from './components/Footer.vue'
import Hamburger from './components/Hamburger.vue'
import CookiesPanel from './components/CookiesPanel.vue'
import CookiesDialog from './components/CookiesDialog.vue'

export default {
  name: 'App',
  components: {
    Menu,
    MenuMobile,
    Footer,
    Hamburger,
    CookiesPanel,
    CookiesDialog
  },
  data() {
    return {
      selectedMenu: {
        0: null,
        1: null
      }
    }
  },
  computed: {
    ...mapState([
      'menus',
      'version',
      'config'
    ]),
    drawer: {
      get() { return this.$store.state.drawer },
      set(value) { this.$store.commit('setDrawer', value) }
    },
    builtVersion() {
      const v = []
      switch (process.env.VUE_APP_TARGET) {
        case 'testing':
        case 'production':
          v.push(process.env.VUE_APP_TARGET)
          v.push(`front v${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_COMMIT})`)
          v.push(`api v${this.version.version} (${this.version.hash})`)
          return v.join(' - ')
        default: return 'Development'
      }
    }
  },
  created() {
    this.$store.dispatch('getConfig')
    this.$store.dispatch('getMenus')
    this.$store.dispatch('getVersion')
  },
  methods: {
    leaveMenu($event) {
      if ($event) {
        let parent = $event.toElement
        while (parent) {
          if (parent.classList.contains('menu-mouseleave-target')) return
          parent = parent.parentElement
        }
      }
      this.selectedMenu[0] = null
      this.selectedMenu[1] = null
    },
    mouseleave($event) {
      setTimeout(() => this.leaveMenu($event))
    }
  }
}
</script>

<style lang="sass">
[role=link],
[role=button]
  cursor: pointer

a
  text-decoration: none

  .tsc-app__footer &:hover
    text-decoration: underline

.headline-2
  font-size: 1.9rem !important
  font-weight: 500
  line-height: 2.5rem

.not-clickable
  cursor: inherit !important

  &:focus:before
    content: none

.v-image__image--preload
  filter: none
  background-size: auto 100px

.tsc-app
  &.theme--light.v-application
    color: var(--v-secondary-base)

  .v-btn
    text-transform: initial
    letter-spacing: initial

    &__content
      margin: 0 6px

    &.v-size--default
      font-size: 1.1rem

    &.v-size--small
      font-size: .95rem

  &__app-bar
    .v-toolbar__content
      padding: 0

  .menu-button
    &:hover:before,
    &.v-btn--active:before
      opacity: 0 !important

    .tsc-picto
      opacity: 0

    &:hover .tsc-picto,
    &.active .tsc-picto
      opacity: 1

.tsc-separator
  background: radial-gradient(var(--v-primary-base) 40%, transparent 40%) -20px -16px
  background-size: 80px 40px
  background-color: var(--v-accent-base)
  height: 8px
  border-radius: 4px
  border: 0

  &--light
    background: radial-gradient(white 40%, transparent 40%) -10px -8px
    background-size: 40px 20px
    background-color: var(--v-accent-base)
    height: 4px
    border-radius: 2px

.menu-divider
  background: radial-gradient(var(--v-secondary-base) 15%, transparent 15%, transparent 70%) 5px -5px
  background-size: 10px 10px
  border-color: transparent !important
  border-width: 2px !important
  margin-left: -4px

.tsc-picto
  display: inline-block
  position: relative
  height: 18px
  width: 10px

  &.d-inline
    &:before,
    &:after
      left: 10px

  &:before
    content: ''
    position: absolute
    width: 10px
    height: 10px
    border-radius: 5px
    background: var(--v-accent-base)
    left: 0
    top: calc(50% - 9px)

  &:after
    content: ''
    position: absolute
    width: 0
    height: 0
    border-style: solid
    border-color: transparent
    border-bottom-width: 0
    border-top-width: 10px
    border-left-width: 3px
    border-right-width: 3px
    border-top-color: var(--v-accent-base)
    left: 0
    top: calc(50% - 1px)
    transform: rotate(15deg)

.dialog-close
  position: absolute
  right: 10px
  top: 10px
  width: 50px
  height: 50px
  z-index: 1

  &:before,
  &:after
    content: ''
    position: absolute
    left: 14px
    top: 22px
    width: 20px
    height: 4px
    border-radius: 2px

  &:before
    background: var(--v-accent-base)
    z-index: 1
    transform: rotate(45deg)

  &:after
    background: var(--v-primary-base)
    transform: rotate(-45deg)
</style>
