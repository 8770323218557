var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"tsc-app",staticStyle:{"background":"white"}},[_c('v-container',{staticClass:"pa-0",staticStyle:{"z-index":"2","background":"white"},attrs:{"fluid":""}},[_c('v-container',{staticClass:"py-0"},[_c('v-app-bar',{staticClass:"tsc-app__app-bar",attrs:{"elevation":"0","height":_vm.$vuetify.breakpoint.smAndDown ? 120 : 160}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{attrs:{"aria-label":"toggle-menu-button"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('Hamburger',{attrs:{"active":_vm.drawer}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),_c('router-link',{staticClass:"d-block pr-12",attrs:{"to":{ name: 'home' },"aria-label":"Trouver son cadeau - accueil"}},[_c('v-img',{attrs:{"src":"/logo.svg","alt":"Trouver son cadeau logo","width":_vm.$vuetify.breakpoint.mdAndUp ? '198px' : '160px'}})],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),(
            _vm.menus.length
            && _vm.$vuetify.breakpoint.mdAndUp
          )?_c('v-toolbar-items',{staticClass:"menu-mouseleave-target",on:{"mouseleave":function($event){return _vm.mouseleave($event)}}},_vm._l((_vm.menus),function(menu){return _c('v-btn',_vm._b({key:menu.id,staticClass:"menu-button",class:{
              'active': _vm.selectedMenu[0] && _vm.selectedMenu[0].id === menu.id
            },attrs:{"text":"","ripple":false,"active-class":"active","exact":""},on:{"mouseenter":function($event){_vm.selectedMenu[0] = menu.children.length ? menu : null,
              _vm.selectedMenu[1] = menu.children.length ? menu.children[0] : null}}},'v-btn',{
              to: menu.page_uri && { name: 'page', params: { uri: menu.page_uri.split('/') } }
            },false),[_c('span',[_vm._v(_vm._s(menu.name))]),_c('span',{staticClass:"\n                ml-2\n                tsc-picto\n              "})])}),1):_vm._e()],1)],1)],1),(_vm.menus.length)?_c('MenuMobile'):_vm._e(),_c('Menu',{attrs:{"selectedMenu":_vm.selectedMenu},on:{"mouseleave":function($event){return _vm.mouseleave($event)}}}),_c('v-overlay',{attrs:{"z-index":"1","value":!!_vm.selectedMenu[0]}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100vw","height":"100vh"},on:{"click":function($event){return _vm.leaveMenu()}}})]),(_vm.config)?_c('v-content',{staticClass:"tcs-app__content"},[_c('v-container',[_c('router-view')],1)],1):_vm._e(),_c('Footer'),_c('CookiesPanel'),_c('CookiesDialog'),(_vm.version)?_c('span',{staticClass:"caption",staticStyle:{"position":"absolute","bottom":"0","right":"0","color":"rgba(255,255,255,.2)"}},[_vm._v(_vm._s(_vm.builtVersion))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }