<template>
  <v-row v-if="total > itemsPerPage">
    <v-col>
      <div
        class="text-center"
        :class="{
          'subtitle-1 font-weight-medium': $vuetify.breakpoint.xs,
          'title': $vuetify.breakpoint.smAndUp
        }"
      >Vous avez vu {{ displayed }} articles sur {{ total }}</div>

      <div
        style="width: 200px; margin: auto"
        class="pa-6"
      >
        <v-progress-linear
          height="10"
          style="border-radius: 5px"
          background-color="primary"
          color="accent"
          :value="Math.floor(displayed * 100 / total)"
        ></v-progress-linear>
      </div>

      <div class="d-flex justify-center">
        <v-btn
          rounded
          :small="$vuetify.breakpoint.xs"
          depressed
          color="accent"
          @click="loadMore()"
          :disabled="!itemsLeft"
        >Voir plus</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoadMoreProducts',
  computed: {
    ...mapState(['itemsLeft', 'itemsPerPage', 'total']),
    itemsLeftText() {
      return `${this.itemsLeft} restant${this.itemsLeft > 1 ? 's' : ''}`
    },
    displayed() {
      return this.total - this.itemsLeft
    }
  },
  methods: {
    loadMore() {
      this.$store.dispatch('loadMoreProducts')
    }
  }
}
</script>
