var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[(_vm.selectedMenu[0])?_c('v-container',{staticClass:"\n      pb-0\n      px-0\n      menu-mouseleave-target\n      tsc-main-menu\n    ",attrs:{"fluid":""},on:{"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[(_vm.selectedMenu[0].nbLevels === 3)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 3 : 2}},[_c('MenuLevel1',{attrs:{"items":_vm.selectedMenu[0].children,"selectedMenu":_vm.selectedMenu}})],1):_vm._e(),(_vm.selectedMenu[0].nbLevels === 3)?_c('v-divider',{staticClass:"my-3 menu-divider",attrs:{"vertical":""}}):_vm._e(),_c('v-col',{staticClass:"py-0",class:{
            'pl-6': _vm.selectedMenu[0].nbLevels === 3
          },attrs:{"cols":_vm.selectedMenu[0].nbLevels === 3
            ? (
              _vm.$vuetify.breakpoint.mdAndDown
              ? (_vm.config.menu_ad_url ? 7 : 9)
              : (_vm.config.menu_ad_url ? 8 : 10)
            )
            : (_vm.config.menu_ad_url ? 10 : 12)}},[_c('MenuLevel2',{attrs:{"item":_vm.selectedMenu[0].nbLevels === 3 ? _vm.selectedMenu[1] : _vm.selectedMenu[0]}})],1),(_vm.config.menu_ad_url)?_c('v-col',{attrs:{"cols":"2"}},[_c('a',{attrs:{"href":_vm.config.menu_ad_url,"rel":"noopener","target":"_blank","aria-label":"Menu ad"}},[_c('v-img',{attrs:{"src":_vm.config.menu_ad_img,"alt":"menu ad","height":"250px","position":"right center","contain":""}})],1)]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }