<template>
  <div>
    <div
      class="
        d-flex
        align-center
        py-1
      "
      v-for="item in items"
      :key="item.id"
      @mouseenter="selectedMenu[1] = item.children.length ? item : null"
    >
      <router-link
        v-if="item.page_uri"
        :to="{
          name: 'page',
          params: { uri: item.page_uri.split('/') }
        }"
        class="font-weight-medium"
      >{{ item.name }}</router-link>

      <span
        v-if="!item.page_uri"
        class="font-weight-medium"
      >{{ item.name }}</span>

      <span
        class="ml-2 tsc-picto"
        :style="{
          opacity: selectedMenu[1] && selectedMenu[1].id === item.id ? 1 : 0
        }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuLevel1',
  props: {
    items: Array,
    selectedMenu: Object
  }
}
</script>
