const RecaptchaPlugin = {
  install(Vue) {
    if (/trouversoncadeau\.com/.test(window.location.hostname)) {
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`
      document.body.appendChild(script)
    }

    async function execute(action) {
      const token = await grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action })
      return token
    }

    Object.defineProperties(Vue.prototype, {
      $recaptcha: { get() { return { execute } } }
    })
  }
}

export default RecaptchaPlugin
