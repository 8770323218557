import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faShippingFast, faGift } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtm from 'vue-gtm'

import Axios from './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Filters from './plugins/filters'
import SeoPlugin from './plugins/seo'
import RecaptchaPlugin from './plugins/recaptcha'
import PerfectScrollbarPlugin from './plugins/perfect-scrollbar'
import LineClamp from './directives/line-clamp'

library.add(faCheck, faShippingFast, faGift, faFacebookF, faTwitter, faInstagram)

Vue.config.productionTip = false

Vue.use(Axios)
Vue.use(Filters)
Vue.use(SeoPlugin)
Vue.use(RecaptchaPlugin)
Vue.use(PerfectScrollbarPlugin)
Vue.component('fa-icon', FontAwesomeIcon)
Vue.directive('line-clamp', LineClamp)

if (process.env.VUE_APP_GTM) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
    vueRouter: router
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
